import { parseMeasurement } from 'utils';
import { Descendant } from 'slate';

import { HistoryManager, ReduxInterface, ExtensionHelper } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import ShortcutsManager from '../ShortcutsManager';
import FindAndReplace from '../FindAndReplace';
import EventsManager from '../EventsManager';
import SpellCheck from '../SpellCheck';
import SelectionManager from '../SelectionManager';
import NavigationManager from '../NavigationManager';
import { ChangeTracker } from '../ChangeTracker';
import { DocumentParser } from '../Parsers';
import { StylesHandler } from 'Editor/services/Styles';
import { transportInitializer, dataManagerInitializer } from './Initializers';

import {
  CommentElement,
  PageElement,
  RenderMode,
  RENDER_MODES,
  TemporaryCommentElement,
  TrackDeleteElement,
  TrackInsertElement,
  VisualizerManager,
} from 'Editor/services/VisualizerManager';
import {
  CitationData,
  DocumentStyleData,
  StatusValue,
  SuggestionDataPriority,
  TaskDataLongProps,
} from '../DataManager';
import { EditorSelectionUtils, JsonRange, SelectionModifier } from '../_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from '../_Common/DOM';
import { LocalStorage } from '_common/utils';
import OldEditionManager from '../EditionManager/EditionManager';
import { Logger } from '_common/services';
import { ELEMENTS, INDENT_TYPE } from '../consts';
import { notify } from '_common/components/ToastSystem';
import EditionManager from '../Edition_v2/EditionManager';
import { FontFamilyHelper } from '_common/utils/FontFamilyHelper';

const EDITOR_STATUS = {
  INITIALIZING: 'INITIALIZING',
  INITIALIZED: 'INITIALIZED',
  DESTROYING: 'DESTROYING',
  DESTROYED: 'DESTROYED',
} as const;

class EditorManager {
  static get STATUS() {
    return EDITOR_STATUS;
  }

  static instance: EditorManager | null = null;
  static editorInstances: EditorManager[] = [];

  protected debug: boolean;

  protected __uta_timeout?: NodeJS.Timeout;
  protected __uta_actions?: any[];

  protected _setSelectiontimeout: NodeJS.Timeout | null = null;
  protected _reloadTimeout: NodeJS.Timeout | null = null;

  protected initializeTransport: Function;
  protected dataManagerInitializer: Function;

  protected editorContext: Editor.Context;

  private subscriptions: any[];

  //! edition refactor temporary
  private editionVersion: 1 | 2 = 2;

  constructor() {
    this.debug = true;

    this.editorContext = {
      editorStatus: undefined,
      documentContainer: undefined,
      selection: {},
    };

    this.subscriptions = [];

    // BIND ALL METHODS FROM THIS OBJECT
    const objectProperties = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    for (let i = 0; i < objectProperties.length; i++) {
      const prop = objectProperties[i];

      //@ts-expect-error
      if (this[prop] instanceof Function) {
        switch (prop) {
          case 'constructor':
            break;

          case 'initializeTransport':
            this.initializeTransport = transportInitializer.bind(this);
            break;
          case 'dataManagerInitializer':
            this.dataManagerInitializer = dataManagerInitializer.bind(this);
            break;

          default:
            //@ts-expect-error
            this[prop] = this[prop].bind(this);
            break;
        }
      }
    }

    this.initializeTransport = transportInitializer.bind(this);
    this.dataManagerInitializer = dataManagerInitializer.bind(this);
  }

  /**
   * @return {EditorManager} The singleton instance of the EditorManager class
   */
  static getInstance(): EditorManager {
    if (!EditorManager.instance) {
      if (EditorManager.editorInstances.length > 0) {
        Logger.captureException(new Error('Editor Manager trying to create duplicated instances'));
        EditorManager.instance = EditorManager.editorInstances[0];
      } else {
        EditorManager.instance = new EditorManager();
        EditorManager.editorInstances.push(EditorManager.instance);
      }
    }
    return EditorManager.instance;
  }

  static get RENDER_MODES() {
    return RENDER_MODES;
  }

  get renderMode(): RenderMode | undefined {
    return this.editorContext.visualizerManager?.getRenderMode();
  }

  get layoutType(): Editor.Visualizer.LayoutType | undefined {
    return this.editorContext.visualizerManager?.getLayoutType();
  }

  get isWebLayout(): boolean {
    return this.layoutType === 'WEB';
  }

  get isEditorRunning(): boolean {
    return this.editorContext.editorStatus === EditorManager.STATUS.INITIALIZED;
  }

  get selectionManager(): SelectionManager | undefined {
    return this.editorContext.selectionManager;
  }

  get DataManager(): Editor.Data.API | undefined {
    return this.editorContext.DataManager;
  }

  get fontFamilyHelper(): FontFamilyHelper | undefined {
    return this.editorContext.visualizerManager?.getFontFamilyHelper();
  }

  get stylesHandler(): StylesHandler | undefined {
    return this.editorContext.stylesHandler;
  }

  get navigationManager(): NavigationManager | undefined {
    return this.editorContext.navigationManager;
  }

  get editionManager(): OldEditionManager | Editor.EditionManager | undefined {
    return this.editorContext.editionManager;
  }

  get shortcutsManager(): ShortcutsManager | undefined {
    return this.editorContext.shortcutsManager;
  }

  get spellCheck(): SpellCheck | undefined {
    return this.editorContext.spellCheck;
  }

  get visualizerManager(): Editor.Visualizer.API | undefined {
    return this.editorContext.visualizerManager;
  }

  debugMessage(message: string, ...args: any[]) {
    if (this.debug) {
      Logger.trace(message, ...args);
    }
  }

  getUser(): Realtime.Core.User | undefined {
    return this.editorContext.user;
  }

  //! edition refactor temporary
  setEditionVersion(version: 1 | 2) {
    if (this.editionVersion === version) {
      return;
    }

    this.editionVersion = version;
    if (this.editionVersion === 1) {
      this.editorContext.editionManager?.destroy();
      this.editorContext.editionManager = new OldEditionManager();
      this.editorContext.editionManager?.start(this.editorContext);
    } else if (this.editionVersion === 2) {
      this.editorContext.editionManager?.destroy();
      this.editorContext.editionManager = new EditionManager();
      this.editorContext.editionManager.start(this.editorContext);
    }

    if (this.editorContext.visualizerManager?.Visualizer) {
      this.editorContext.visualizerManager.Visualizer.editionManager =
        this.editorContext.editionManager;
    }
    this.setEditionMode();
  }

  getEditionVersion() {
    return this.editionVersion;
  }

  //! TEST EXTENSION ONLY
  setExtensionId(id: string) {
    ExtensionHelper.EXTENSION_ID = id;
    return 'Extension ID set to:' + ExtensionHelper.EXTENSION_ID;
  }

  getExtensionId() {
    return ExtensionHelper.EXTENSION_ID;
  }

  // ####################################################
  //                  Initializations
  // ####################################################
  initializeServices(docId?: string, user?: Realtime.Core.User) {
    this.debugMessage('EditorManager initialize', docId, user);
    if (docId != null && user != null) {
      if (this.editorContext.editorStatus === EditorManager.STATUS.INITIALIZING) {
        return;
      }

      this.debugMessage('EditorManager initializing', docId, user);

      if (this.isEditorRunning) {
        Logger.captureException(new Error('Editor is already running! Destroying!!'));
        this.destroy();
      }

      this.editorContext.editorStatus = EditorManager.STATUS.INITIALIZING;

      this.editorContext.documentId = docId;
      this.editorContext.user = user;

      Logger.setTag('document', this.editorContext.documentId);
      Logger.setTag('tenant', LocalStorage.getTenant());

      // its supposed to do this only in DEV environment
      /* develblock:start */
      if (!global.editorManager) {
        global.editorManager = EditorManager.instance;
      }
      //@ts-expect-error
      if (!global.EditorSelectionUtils) {
        //@ts-expect-error
        global.EditorSelectionUtils = EditorSelectionUtils;
      }
      //@ts-expect-error
      if (!global.EditorDOMUtils) {
        //@ts-expect-error
        global.EditorDOMUtils = EditorDOMUtils;
      }
      /* develblock:end */

      // wait for page ready
      EditorDOMUtils.waitForNodeToRender(this.editorContext.documentId).then(() => {
        const container = document.getElementById(this.editorContext.documentId as string);
        if (container != null) {
          this.editorContext.documentContainer = container;
        }

        this.initializeTransport();
      });
    } else {
      throw new Error('Invalid data to initialize!');
    }
  }

  protected initializeCoreServices() {
    logger.trace('initializeCoreServices');

    this.dataManagerInitializer();

    this.editorContext.eventsManager = EventsManager.getInstance();
    this.editorContext.historyManager = HistoryManager.getInstance();
    this.editorContext.selectionManager = new SelectionManager(this.editorContext);

    this.editorContext.stylesHandler = new StylesHandler(this.editorContext);

    this.editorContext.documentParser = new DocumentParser(this.editorContext);
    //@ts-expect-error
    this.editorContext.changeTracker = new ChangeTracker(this.editorContext);
    this.editorContext.navigationManager = new NavigationManager(this.editorContext);

    //! edition refactor temporary
    if (this.editionVersion === 1) {
      this.editorContext.editionManager = new OldEditionManager();
    } else if (this.editionVersion === 2) {
      this.editorContext.editionManager = new EditionManager();
    }

    this.editorContext.shortcutsManager = new ShortcutsManager(ReduxInterface.getPlatformInfo());
    this.editorContext.spellCheck = new SpellCheck(this.editorContext);
    this.editorContext.visualizerManager = VisualizerManager(this.editorContext);

    if (this.editorContext.selection && this.editorContext.DataManager) {
      this.editorContext.selection.modifiers = new SelectionModifier(
        this.editorContext.DataManager,
      );
    }

    this.startCoreServices();
  }

  protected startCoreServices() {
    logger.trace('startCoreServices');

    if (this.editorContext.documentId && this.editorContext.user) {
      this.setupSubscriptions();

      this.editorContext.eventsManager?.startEventsManager(this.editorContext.documentContainer);

      this.editorContext.historyManager?.start(this.editorContext);

      this.editorContext.shortcutsManager?.start();

      this.editorContext.editionManager?.start(this.editorContext);

      this.stylesHandler?.start();

      this.editorContext.DataManager?.start(this.editorContext.documentId, this.editorContext.user);
    } else {
      // TODO: throw error?
    }
  }

  protected async handleDocumentReady() {
    logger.trace('handleDocumentReady');

    // TODO: move start to document ready??? or start after data mananger
    if (this.editorContext.documentContainer) {
      await this.visualizerManager?.start(this.editorContext.documentContainer);
    }

    this.editorContext.editorStatus = EditorManager.STATUS.INITIALIZED;
    Logger.setTag('Editor Running', this.isEditorRunning);

    this.setEditionMode();
  }

  setEditionMode() {
    const readonly = this.visualizerManager?.isReadOnly();

    if (readonly || this.renderMode !== RENDER_MODES.BASIC) {
      this.disableEditionCapabilities();
    } else {
      switch (this.layoutType) {
        case 'PAGE':
          this.setPageLayoutCapabilities();
          break;
        case 'WEB':
          this.setWebLayoutCapabilities();
          break;
        default:
          this.disableEditionCapabilities();
          break;
      }
    }

    ReduxInterface.setVisible(true);
    ReduxInterface.stopEditorLoading();
  }

  setWebLayoutCapabilities() {
    logger.trace('setWebLayoutCapabilities');
    if (this.shortcutsManager?.isPaused) {
      this.shortcutsManager.resume();
    }

    let trackingChanges = false;

    const trackingState = ReduxInterface.getEditorTrackingState();

    if (trackingState.thirdParty) {
      trackingChanges = trackingState.state;
    } else {
      const docTrackingState = this.DataManager?.document?.getDocumentTracking();

      if (docTrackingState.lock) {
        trackingChanges = docTrackingState.state;
        ReduxInterface.setTracking({
          ...docTrackingState,
          userId: this.editorContext.user?.id,
          documentId: this.editorContext.documentId,
        });
      } else {
        trackingChanges = trackingState.state;
      }
    }

    if (trackingChanges) {
      this.editionManager?.enableSuggestionMode();
    } else {
      this.editionManager?.enableNormalMode();
    }

    this.setInitialSelectionStatus();

    ReduxInterface.setEditable(true);
  }

  setPageLayoutCapabilities() {
    logger.trace('setPageLayoutCapabilities');
    if (this.shortcutsManager?.isPaused) {
      this.shortcutsManager.resume();
    }

    const trackingState = ReduxInterface.getEditorTrackingState();

    if (!trackingState.thirdParty) {
      const docTrackingState = this.DataManager?.document?.getDocumentTracking();

      if (docTrackingState.lock) {
        ReduxInterface.setTracking({
          ...docTrackingState,
          userId: this.editorContext.user?.id,
          documentId: this.editorContext.documentId,
        });
      }
    }

    if (this.editionManager) {
      this.editionManager.disableEdition();
    }

    this.setInitialSelectionStatus();

    ReduxInterface.setEditable(false);
  }

  disableEditionCapabilities() {
    if (this.shortcutsManager) {
      this.shortcutsManager.pause();
    }

    if (this.editionManager) {
      this.editionManager.disableEdition();
    }

    this.setInitialSelectionStatus();

    ReduxInterface.setEditable(false);
  }

  setInitialSelectionStatus(timeout: number = 0) {
    if (timeout > 1000) {
      // fail safe
      return;
    }

    if (this.selectionManager) {
      if (this._setSelectiontimeout) {
        clearTimeout(this._setSelectiontimeout);
        this._setSelectiontimeout = null;
      }
      this._setSelectiontimeout = setTimeout(() => {
        if (this.isEditorRunning && this.selectionManager != null) {
          this.visualizerManager?.selection.stopSelectionTracker();
          try {
            const pageNode = this.editorContext.documentContainer;
            if (pageNode && pageNode.childNodes.length > 0) {
              if (!pageNode.contains(document.activeElement)) {
                pageNode.focus({
                  preventScroll: true,
                });
              }
              let elementToSelect;

              if (this.layoutType === 'PAGE') {
                const pageElement = pageNode.childNodes[0] as PageElement;
                elementToSelect = pageElement?.sectionContainerAt(0)?.childNodes[0] as HTMLElement;
              } else {
                elementToSelect = pageNode.childNodes[0] as HTMLElement;
              }

              if (elementToSelect && elementToSelect.tagName !== ELEMENTS.LoaderElement.TAG) {
                EditorSelectionUtils.setCaret(elementToSelect, 'INDEX', 0);
                this.visualizerManager?.selection.triggerSelectionChanged();
                ReduxInterface.setParagraphsLoaded(true);
              } else {
                this.setInitialSelectionStatus(timeout + 200);
              }
            } else {
              this.setInitialSelectionStatus(timeout + 200);
            }
          } catch (error) {
            Logger.captureException(error);
          } finally {
            this.visualizerManager?.selection.debounceStartSelectionTracker();
          }
        }
      }, timeout);
    }
  }

  // ####################################################
  //                  Subscriptions
  // ####################################################
  setupSubscriptions() {
    this.shortcutsManager?.on('TOGGLE_SELECTION_STYLE', ({ style, value, toRemove }) => {
      if (toRemove) {
        this.removeSelectionStyle(style, value);
      } else {
        this.toggleSelectionStyle(style, value);
      }
    });
    this.shortcutsManager?.on('ALIGN_CURRENT_SELECTION', this.alignCurrentSelection);
    this.shortcutsManager?.on('UNDO', this.undoListener);
    this.shortcutsManager?.on('REDO', this.redoListener);
    this.shortcutsManager?.on('SELECT_ALL', this.handleSelectAll);
    this.shortcutsManager?.on('INSERT_PAGE_BREAK', this.insertPageBreak);
    this.shortcutsManager?.on('CHANGE_PARAGRAPH_STYLE', this.applyDocumentStyleToSelection);
    this.shortcutsManager?.on('OPEN_SAVE_VERSION', this.openSaveVersion);
    this.shortcutsManager?.on('START_REVERT_VERSION', () => {
      ReduxInterface.startRevertingVersion();
    });
    this.shortcutsManager?.on('OPEN_FIND_AND_REPLACE', this.openFindAndReplace);
    this.shortcutsManager?.on('FIND_NEXT', this.findNext);
    this.shortcutsManager?.on('FIND_PREVIOUS', this.findPrevious);
    this.shortcutsManager?.on('ZOOM_IN', this.zoomIn);
    this.shortcutsManager?.on('ZOOM_OUT', this.zoomOut);
  }

  restartOnTabFocus(docId: string, user: Realtime.Core.User) {
    if (!this.isEditorRunning && !ReduxInterface.getEditorLoadingState()) {
      ReduxInterface.clearEditorError();
      this.reloadEditorManager(docId, user);
    }
  }

  // ####################################################
  //                  Destructions
  // ####################################################
  static destroyAllCards() {
    try {
      const commentsState = ReduxInterface.getCommentsState();

      if (commentsState.insert.inserting) {
        ReduxInterface.cancelTemporaryComment();
      }

      if (ReduxInterface.getSelectedTask()) {
        ReduxInterface.setTaskOverlayData({ selected: null, offsets: null });
      }

      ReduxInterface.setNoteOverlayData({
        offsets: null,
      });
    } catch (e) {
      Logger.captureException(e);
    }
  }

  protected destroyTransportService() {
    setTimeout(() => {
      if (this.editorContext.transport) {
        try {
          this.editorContext.transport.destroy();
        } catch (e) {
          Logger.captureException(e);
        } finally {
          delete this.editorContext.transport;
        }
      }
    }, 0);
  }

  protected destroyCoreServices() {
    if (this.editorContext.DataManager) {
      try {
        this.editorContext.DataManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.DataManager;
      }
    }
  }

  protected destroyEdtionServices() {
    if (HistoryManager.getInstance()) {
      try {
        HistoryManager.getInstance().stop();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.historyManager;
      }
    }

    if (this.editorContext.selectionManager) {
      try {
        this.editorContext.selectionManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.selectionManager;
      }
    }

    if (this.editorContext.navigationManager) {
      try {
        this.editorContext.navigationManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.navigationManager;
      }
    }
  }

  protected destroyVisualizerManager() {
    if (this.editorContext.visualizerManager) {
      try {
        this.editorContext.visualizerManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.visualizerManager;
      }
    }
  }

  protected destroyPeripheralServices() {
    if (this.editorContext.shortcutsManager) {
      try {
        this.editorContext.shortcutsManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.shortcutsManager;
      }
    }
  }

  protected destroySpellCheck() {
    if (this.editorContext.spellCheck) {
      try {
        this.editorContext.spellCheck.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.spellCheck;
      }
    }
  }

  protected destroyStylesHandler() {
    if (this.editorContext.stylesHandler) {
      try {
        this.editorContext.stylesHandler.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.stylesHandler;
      }
    }
  }

  protected destroyEditionManager() {
    // destroy
    if (this.editorContext.editionManager) {
      try {
        this.editorContext.editionManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.editionManager;
      }
    }
  }

  protected destroyChangeTracker() {
    if (this.editorContext.changeTracker) {
      try {
        //@ts-expect-error
        this.editorContext.changeTracker.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.changeTracker;
      }
    }
  }

  protected destroyEventHandling() {
    if (this.editorContext.eventsManager) {
      try {
        this.editorContext.eventsManager.destroy();
      } catch (e) {
        Logger.captureException(e);
      } finally {
        delete this.editorContext.eventsManager;
      }
    }
  }

  terminateServices() {
    if (
      this.editorContext.editorStatus !== EditorManager.STATUS.DESTROYING &&
      this.editorContext.editorStatus !== EditorManager.STATUS.DESTROYED
    ) {
      this.debugMessage('EditorManager terminate services');
      this.editorContext.editorStatus = EditorManager.STATUS.DESTROYING;
      if (this.subscriptions && this.subscriptions.length) {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
      }

      if (this.editorContext.findAndReplace) {
        delete this.editorContext.findAndReplace;
      }

      EditorManager.destroyAllCards();

      this.destroyEventHandling();
      this.destroyEditionManager();
      this.destroySpellCheck();
      this.destroyChangeTracker();
      this.destroyStylesHandler();
      this.destroyPeripheralServices();
      this.destroyEdtionServices();
      this.destroyVisualizerManager();
      this.destroyCoreServices();

      if (this.editorContext.transport) {
        // fail safe to remove event listeners from transport
        this.editorContext.transport.clearAllEventListeners();
      }

      this.editorContext.editorStatus = EditorManager.STATUS.DESTROYED;
      Logger.setTag('Editor Running', this.isEditorRunning);
    }
  }

  destroy() {
    try {
      this.debugMessage('EditorManager destroy');
      this.terminateServices();
      this.destroyTransportService();
    } catch (e) {
      Logger.error(e);
    } finally {
      // if (EditorManager.instance) {
      //   const index = EditorManager.editorInstances.indexOf(EditorManager.instance);

      //   EditorManager.editorInstances.splice(index, 1);
      // EditorManager.instance = null;
      // }

      /* develblock:start */
      global.editorManager = null;
      //@ts-expect-error
      global.EditorSelectionUtils = null;
      //@ts-expect-error
      global.EditorDOMUtils = null;

      /* develblock:end */
    }
  }

  // ####################################################
  //                  Other Functions
  // ####################################################
  reloadEditorManager(docId?: string, user?: Realtime.Core.User) {
    this.debugMessage('EditorManager reload');
    ReduxInterface.startEditorLoading();

    if (this._reloadTimeout) {
      clearTimeout(this._reloadTimeout);
    }

    this._reloadTimeout = setTimeout(() => {
      try {
        this.terminateServices();

        this.initializeServices(
          docId || this.editorContext.documentId,
          user || this.editorContext.user,
        );
      } catch (error) {
        Logger.captureException(error);
        this.terminateServices();
        throw error;
      }
    }, 0);
  }

  // CHANGE EDITOR MODE

  changeEditorMode(mode: RenderMode) {
    if (this.isEditorRunning) {
      this.reRender(mode);
    }
  }

  getPage() {
    return this.editorContext.documentContainer;
  }

  isPageEmpty() {
    return this.editorContext.documentContainer?.textContent === '';
  }

  changeLayout(layout: Editor.Visualizer.LayoutType = 'WEB') {
    if (this.isEditorRunning) {
      ReduxInterface.startEditorLoading();

      this.disableEditionCapabilities();
      setTimeout(() => {
        if (this.visualizerManager) {
          this.visualizerManager.changeLayout(layout);
        }

        this.setEditionMode();

        ReduxInterface.setVisible(true);
        ReduxInterface.stopEditorLoading();
      }, 0);
    }
  }

  reRender(mode?: RenderMode) {
    if (this.isEditorRunning) {
      ReduxInterface.startEditorLoading();
      this.visualizerManager?.selection.stopSelectionTracker();

      const loggedUserPermissions = ReduxInterface.getLoggedUserDocumentPermissions();

      let renderMode: RenderMode =
        mode || this.visualizerManager?.getRenderMode() || RENDER_MODES.BASIC;

      const changePermissions =
        loggedUserPermissions?.includes('admin') ||
        loggedUserPermissions?.includes('owner') ||
        loggedUserPermissions?.includes('add_permission') ||
        loggedUserPermissions?.includes('remove_permission');

      const aprove =
        loggedUserPermissions?.includes('admin') ||
        loggedUserPermissions?.includes('owner') ||
        loggedUserPermissions?.includes('approve');

      // check permissions for render mode;
      if (
        (renderMode === RENDER_MODES.PERMISSIONS && !changePermissions) ||
        (renderMode === RENDER_MODES.APPROVALS && !aprove)
      ) {
        const { view } = ReduxInterface.getSidebarState();
        if (view === 'APPROVE_CONTENT' || view === 'CONTENT_PERMISSIONS') {
          ReduxInterface.setSidebarView(null);
        }
        renderMode = RENDER_MODES.BASIC;
      }

      this.visualizerManager?.renderAs(renderMode);

      this.visualizerManager?.selection.debounceStartSelectionTracker();

      this.setEditionMode();
      ReduxInterface.stopEditorLoading();
    }
  }

  forceUpdate(nodeId: string) {
    return this.isEditorRunning && this.DataManager?.nodes?.forceUpdate(nodeId);
  }

  insertSynonym(content: string) {
    if (this.isEditorRunning) {
      this.editionManager?.insertInlineElement(content);
    }
  }

  insertText(content: string) {
    if (this.isEditorRunning) {
      this.editionManager?.insertInlineElement(content);
    }
  }

  removeText() {
    if (this.isEditorRunning) {
      this.editionManager?.handleRemoveSelection();
    }
  }

  isSelectionCollapsed() {
    if (this.isEditorRunning && this.selectionManager) {
      return EditorSelectionUtils.isSelectionCollapsed();
    }
    return false;
  }

  setSelection(startNode: Node, startOffset: number, endNode: Node, endOffset: number) {
    if (this.isEditorRunning && this.DataManager) {
      // EditorSelectionUtils.setSelection(startNode, startOffset, endNode, endOffset);
      const range = EditorSelectionUtils.createNewRange();
      range.setStartAndEnd(startNode, startOffset, endNode, endOffset);
      const json = JsonRange.buildFromDOMRange(range);
      this.DataManager.selection?.setUserSelection([json.serializeToRangeData()]);
    }
  }

  clearSelection() {
    const selection = EditorSelectionUtils.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }

  getSelectedText() {
    if (this.isEditorRunning && this.selectionManager) {
      return EditorSelectionUtils.getSelectedText();
    }
    return null;
  }

  handleSelectAll() {
    if (this.isEditorRunning && this.DataManager) {
      if (this.renderMode === RENDER_MODES.PERMISSIONS) {
        if (this.DataManager?.permissions?.allSelectedForPermissions()) {
          this.deselectAllForPermissions();
        } else {
          this.selectAllForPermissions();
        }
      }
      if (this.renderMode === RENDER_MODES.APPROVALS) {
        if (this.DataManager?.approvals?.allSelectedForApproval()) {
          this.deselectAllForApproval();
        } else {
          this.selectAllForApproval();
        }
      } else if (this.renderMode === RENDER_MODES.BASIC) {
        EditorSelectionUtils.selectAll();
      }
    }
  }

  // ------------------------------------------------------
  //                        Document Structure
  // ------------------------------------------------------
  getWordTemplate() {
    return this.isEditorRunning && this.DataManager?.structure?.getWordTemplate();
  }

  structureHasAutomaticUpdates() {
    return this.isEditorRunning && this.DataManager?.structure?.structureHasAutomaticUpdates();
  }

  // ------------------------------------------------------
  //                        TABLES
  // ------------------------------------------------------

  insertTable(rows: number, columns: number) {
    return this.isEditorRunning && this.editionManager?.insertTable(rows, columns);
  }

  insertRowBefore() {
    return this.isEditorRunning && this.editionManager?.handleInsertRow(true);
  }

  insertRowAfter() {
    return this.isEditorRunning && this.editionManager?.handleInsertRow(false);
  }

  insertRowAtIndex(index: number[]) {
    return this.isEditorRunning && this.editionManager?.handleInsertRowAtIndex(index);
  }

  insertColumnLeft() {
    return this.isEditorRunning && this.editionManager?.handleInsertColumn(true);
  }

  insertColumnRight() {
    return this.isEditorRunning && this.editionManager?.handleInsertColumn(false);
  }

  insertColumnAtIndex(index: number[]) {
    return this.isEditorRunning && this.editionManager?.handleInsertColumnAtIndex(index);
  }

  deleteCells(shiftCells: Editor.Edition.TableOperationArgsType['shiftCells'] = 'SHIFT_LEFT') {
    return this.isEditorRunning && this.editionManager?.handleDeleteCells(shiftCells);
  }

  deleteRows() {
    return this.isEditorRunning && this.editionManager?.handleDeleteRows();
  }

  deleteRowAtIndex(index: number[]) {
    return this.isEditorRunning && this.editionManager?.handleDeleteRowAtIndex(index);
  }

  deleteColumns() {
    return this.isEditorRunning && this.editionManager?.handleDeleteColumns();
  }

  deleteColumnAtIndex(index: number[]) {
    return this.isEditorRunning && this.editionManager?.handleDeleteColumnAtIndex(index);
  }

  deleteTable() {
    return this.isEditorRunning && this.editionManager?.handleDeleteTable();
  }

  mergeCells() {
    return this.isEditorRunning && this.editionManager?.handleMergeCells();
  }

  splitCells(splitColumns = 2, splitRows = 1, mergeCells = false) {
    return (
      this.isEditorRunning &&
      this.editionManager?.handleSplitCells(splitColumns, splitRows, mergeCells)
    );
  }

  sortRows(sortType: Editor.Edition.SortType) {
    return this.isEditorRunning && this.editionManager?.handleSortRows(sortType);
  }

  distribute(type: Editor.Edition.DistributeType) {
    return this.isEditorRunning && this.editionManager?.handleDistribute(type);
  }

  // -----------------------------------------------------------------------
  //                              Citations
  // -----------------------------------------------------------------------

  setReferenceStyle(refStyleId: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager?.citations?.setReferenceStyle(refStyleId);
    }
    return Promise.reject();
  }

  updateCitationInLibrary(data: CitationData) {
    if (this.isEditorRunning && this.DataManager?.citations) {
      return this.DataManager.citations.updateCitationInLibrary(data);
    }
    return Promise.reject();
  }

  addCitationsToLibrary(citations: CitationData[], source?: string) {
    if (this.isEditorRunning && this.DataManager?.citations) {
      return this.DataManager.citations.addCitationsToLibrary(citations, source);
    }
    return Promise.reject();
  }

  addCitationToDocument(citationId: string) {
    if (this.isEditorRunning && this.DataManager?.citations) {
      return this.DataManager.citations.addCitationToDocument(citationId);
    }
    return Promise.reject();
  }

  removeCitationFromLibrary(citationId: string) {
    if (this.isEditorRunning && this.DataManager?.citations) {
      return this.DataManager.citations.removeCitationFromLibrary(citationId);
    }
    return Promise.reject();
  }

  defineCitationPriority(citationId: string, priority: string) {
    if (this.isEditorRunning && this.DataManager?.citations) {
      this.DataManager.citations.defineCitationPriority(citationId, priority);
    }
  }

  addCitation(citationId: string) {
    if (this.isEditorRunning) {
      this.editionManager?.handleInsertCitation(citationId);
    }
  }

  removeCitationLocation(citationId: string, location: string) {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.removeCitationLocation(citationId, location);
    }
  }

  removeCitation(citationId: string) {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.removeCitation(citationId);
    }
  }

  updateCitationsNumbering() {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.updateCitationsNumbering();
    }
  }

  async getCitationsChronologicalOrder() {
    if (this.isEditorRunning) {
      return this.DataManager?.citations?.getCitationsChronologicalOrder();
    }
  }

  getAllCitationsFromLibrary() {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.getAllCitationsFromLibrary();
    }
  }

  getCitationFromLibrary(citationId: string) {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.getCitationFromLibrary(citationId);
    }
  }

  documentCitations() {
    if (this.isEditorRunning) {
      this.DataManager?.citations?.documentCitations();
    }
  }
  // -----------------------------------------------------------------------
  //                              Notes
  // -----------------------------------------------------------------------

  async viewNoteCard(ref: string, type: string, edit = false) {
    if (this.isEditorRunning && this.DataManager?.notes && this.navigationManager) {
      ReduxInterface.setNoteOverlayData({
        offsets: null,
      });
      const location = this.DataManager.notes.getNoteLocation(ref);
      if (location) {
        await this.navigationManager.renderDocumentAtId(location.level0, location.note, {
          position: 'INSIDE_END',
        });
        const noteElement = document.getElementById(location.note);
        const offsets = EditorDOMUtils.getOffsets(noteElement);
        ReduxInterface.setNoteOverlayData({
          offsets,
          type,
          operation: edit ? 'edit' : 'view',
          selected: ref,
        });
      }
    }
  }

  async createNote(id: string | null, type: Notes.NoteType, text: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.notes.addNote(id, type, text);
    }
  }

  insertNote(type: Notes.NoteType, text: string) {
    return this.isEditorRunning && this.editionManager?.handleInsertNote(type, text);
  }

  async editNote(noteId: string, content?: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.notes.editNote(noteId, content);
    }
  }

  async deleteNote(noteId: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.notes.deleteNote(noteId);
    }
  }

  async setPlacement(placement: Notes.NotePlacement) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.notes.setPlacement(placement);
    }
  }

  startCreatingNote(type: Notes.NoteType) {
    //return this.isEditorRunning && this.editionManager?.startCreatingNote(type);
    if (this.isEditorRunning && this.editorContext.navigationManager) {
      if (this.editorContext.navigationManager.isMarkerRendered()) {
        this.editorContext.navigationManager.scrollIntoSelection();

        let range = EditorSelectionUtils.getRange();

        // check if selection is editable
        if (range && EditorSelectionUtils.isCurrentSelectionEditable(range)) {
          let offsets: any = {};
          if (range.endContainer instanceof Text) {
            offsets = EditorDOMUtils.getOffsets(range);
          } else {
            const textNode = document.createTextNode('\u200B');
            range.insertNode(textNode);
            range.setCaret(textNode, 'INDEX', 1);

            offsets = EditorDOMUtils.getOffsets(range);
            textNode.remove();
          }

          ReduxInterface.setNoteOverlayData({
            offsets,
            type,
            operation: 'create',
          });
        } else {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'editor.errors.selectionNonEditable',
          });
        }
      }
    }
  }

  refreshNotes() {
    if (this.isEditorRunning && this.DataManager) {
      return this.isEditorRunning && this.DataManager.notes.refresh();
    }
  }

  // -----------------------------------------------------------------------
  //                              Styles
  // -----------------------------------------------------------------------
  getDocumentColors() {
    return this.stylesHandler?.getDocumentColors();
  }

  clearFormatting() {
    if (this.isEditorRunning && this.stylesHandler) {
      this.stylesHandler.clearFormatting();
    }
  }

  toggleSelectionStyle<T extends Editor.Edition.Styles>(
    style: T,
    value: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    if (this.isEditorRunning && this.isWebLayout) {
      if (this.editionVersion === 1 && this.stylesHandler) {
        this.stylesHandler.toggleStyleAttribute(style, value);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        this.editionManager.toggleStyleValue(style, value);
      }
    }
  }

  removeSelectionStyle<T extends Editor.Edition.Styles>(
    style: T,
    value?: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    if (this.isEditorRunning && this.isWebLayout) {
      if (this.editionVersion === 1 && this.stylesHandler) {
        this.stylesHandler.toggleStyleAttribute(style, value, { toRemove: true });
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        this.editionManager.removeStyleValue(style, value);
      }
    }
  }

  alignCurrentSelection(styleProps: Editor.Elements.TextAlignProperties) {
    if (this.isEditorRunning && this.stylesHandler && this.isWebLayout) {
      if (this.editionVersion === 1) {
        this.stylesHandler.alignCurrentSelection(styleProps);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        this.editionManager.alignCurrentSelection(styleProps);
      }
    }
  }

  // -----------------------------------------------------------------------
  //                              Numbering
  // -----------------------------------------------------------------------
  continueListNumbering() {
    if (this.isEditorRunning) {
      const actionContext: ActionContext = new ActionContext();

      const range = EditorSelectionUtils.getRange();
      if (range) {
        const markers = range.saveRange();
        const nodes = EditorSelectionUtils.getSelectedBlockNodes();
        const li = nodes?.find((node) => {
          if (!(node instanceof HTMLElement)) {
            return false;
          }
          return (
            node.tagName === ELEMENTS.ParagraphElement.TAG &&
            this.DataManager?.numbering?.isListElement(node.id)
          );
        });
        if (!li) {
          range.restoreRange(markers);
          EditorSelectionUtils.applyRangeToSelection(range);
          return;
        }
        if (li instanceof HTMLElement) {
          this.DataManager?.numbering?.continueListNumbering(actionContext, li.id);
        }

        this.editionManager?.handleSaveChanges(actionContext);

        range.restoreRange(markers);
        EditorSelectionUtils.applyRangeToSelection(range);
      }
    }
  }

  updateListNumbering() {
    if (this.isEditorRunning) {
      this.DataManager?.numbering?.updateNumbering();
    }
  }

  restartListNumbering() {
    if (this.isEditorRunning) {
      const actionContext = new ActionContext();

      const range = EditorSelectionUtils.getRange();
      if (range) {
        const markers = range.saveRange();

        const nodes = EditorSelectionUtils.getSelectedBlockNodes();
        const li = nodes?.find((node) => {
          if (!(node instanceof HTMLElement)) {
            return false;
          }
          return (
            node.tagName === ELEMENTS.ParagraphElement.TAG &&
            this.DataManager?.numbering?.isListElement(node.id)
          );
        });
        if (li instanceof HTMLElement) {
          this.DataManager?.numbering?.restartListNumbering(actionContext, li.id);
        }

        this.editionManager?.handleSaveChanges(actionContext);

        range.restoreRange(markers);
        EditorSelectionUtils.applyRangeToSelection(range);
      }
    }
  }

  // -----------------------------------------------------------------------
  //                              Images
  // -----------------------------------------------------------------------
  handleInsertImage(image: File) {
    if (this.isEditorRunning && this.editionManager) {
      return this.editionManager.handleInsertImage(image);
    }
  }

  handleChangeImage(image: File) {
    if (this.isEditorRunning && this.editionManager) {
      return this.editionManager.handleChangeImage(image);
    }
  }

  handleUpdateImageSize(
    image: Editor.Elements.FigureElement | Editor.Elements.ImageElement,
    height: number,
    width: number,
  ) {
    if (this.isEditorRunning && this.editionManager) {
      return this.editionManager.handleUpdateImageSize(image, height, width);
    }
  }

  /**
   * @deprecated
   */
  uploadImage(image: File) {
    if (this.isEditorRunning) {
      // this.restore();
      // //@ts-expect-error
      // this.contentManager?.uploadImage(image);
    }
  }

  handleUpdateImageProperties(properties: Editor.Styles.ImageProperties) {
    if (this.isEditorRunning && this.editionManager) {
      return this.editionManager.handleUpdateImageProperties(properties);
    }
  }

  getSelectedImageProperties(): Editor.Styles.ImageProperties | null {
    if (this.isEditorRunning && this.stylesHandler) {
      return this.stylesHandler.getSelectedImageProperties();
    }
    return null;
  }

  // -----------------------------------------------------------------------
  //                              Tracking State
  // -----------------------------------------------------------------------

  turnTrackingOn() {
    if (this.editionManager) {
      this.editionManager.enableSuggestionMode();
    }
    if (this.DataManager) {
      this.DataManager.suggestions?.toggleTracking(true);
    }
  }

  turnTrackingOff() {
    if (this.editionManager) {
      this.editionManager.enableNormalMode();
    }
    if (this.DataManager) {
      this.DataManager.suggestions?.toggleTracking(false);
    }
  }

  toggleTrackingState() {
    if (this.isEditorRunning && this.DataManager) {
      const userId = this.DataManager.users.loggedUserId;
      const documentId = this.DataManager.document?.getDocumentId();

      if (userId && documentId) {
        ReduxInterface.toggleTracking({
          userId,
          documentId,
        });
      }
    }
  }

  async lockTrackingState(lock: boolean) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.suggestions?.lockTracking(
        ReduxInterface.getEditorTrackingState().state,
        lock,
      );
    }
  }

  // -----------------------------------------------------------------------
  //                              Approvals
  // -----------------------------------------------------------------------

  getApprovalSummary(elementIds?: string[]) {
    if (this.isEditorRunning) {
      this.DataManager?.approvals?.documentApprovalSummary(elementIds);
    }
  }

  selectNodesForApproval(elementIds: string[]) {
    if (this.isEditorRunning) {
      this.DataManager?.approvals?.selectBlocksForApproval(elementIds);
    }
  }

  selectAllForApproval() {
    if (this.isEditorRunning) {
      // TODO : LOADING STATE
      this.DataManager?.approvals?.selectAllBlocksForApproval();
    }
  }

  deselectAllForApproval() {
    if (this.isEditorRunning) {
      // TODO : LOADING STATE
      this.DataManager?.approvals?.deselectAllBlocksForApproval();
    }
  }

  async approveNodes(nodeIds: string[]): Promise<void> {
    if (this.isEditorRunning) {
      const blockApprovalsState = ReduxInterface.getBlockApprovalsState();
      if (Object.keys(blockApprovalsState.summary.hasSuggestions).length > 0) {
        notify({
          type: 'error',
          title: 'CANNOT_APPROVE_BLOCK',
          message: 'CANNOT_APPROVE_THIS_BLOCK_BECAUSE_IT_CONTAINS_UNRESOLVED_SUGGESTIONS',
        });
      } else {
        await this.DataManager?.approvals?.approveNodes(nodeIds);
      }
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async reopenNodes(nodeIds: string[]): Promise<void> {
    if (this.isEditorRunning && this.DataManager) {
      const result = await this.DataManager?.approvals?.reopenNodes(nodeIds);

      if (result?.success) {
        ReduxInterface.closeAndResetModal('ReopenBlock');
      }
      if (result?.conflict) {
        ReduxInterface.openAndUpdateModal({
          modal: 'ReopenBlock',
          data: {
            list: result.payload?.needsReopening,
          },
        });
      }
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  // -----------------------------------------------------------------------
  //                              Permissions
  // -----------------------------------------------------------------------
  canUserPerform(
    blocksIds: string[],
    action: Permissions.ObjectPermissionsType | Permissions.BlockPermissionsType,
  ) {
    if (!this.isEditorRunning) {
      return false;
    }

    return blocksIds.reduce((prevCheck, blockId) => {
      return prevCheck && !!this.DataManager?.permissions?.canUserPerform(blockId, action);
    }, true);
  }
  selectNodesForPermissions(elementIds: string[]) {
    if (this.isEditorRunning) {
      this.DataManager?.permissions?.selectBlocksForPermissions(elementIds);
    }
  }

  selectAllForPermissions() {
    if (this.isEditorRunning) {
      // TODO : LOADING STATE
      this.DataManager?.permissions?.selectAllBlocksForPermissions();
    }
  }

  deselectAllForPermissions() {
    if (this.isEditorRunning) {
      // TODO : LOADING STATE
      this.DataManager?.permissions?.deselectAllBlocksForPermissions();
    }
  }

  addBlockPermission(nodes: string[], user: any, permission: any) {
    if (this.isEditorRunning) {
      this.DataManager?.permissions?.addPermissionForUser(nodes, user, permission);
    }
  }

  removeBlockPermission(nodes: string[], user: any, permission: any) {
    if (this.isEditorRunning) {
      this.DataManager?.permissions?.removePermissionForUser(nodes, user, permission);
    }
  }

  removeUserFromBlock(nodes: string[], user: any) {
    if (this.isEditorRunning) {
      this.DataManager?.permissions?.removeUserForPermission(nodes, user);
    }
  }

  // -----------------------------------------------------------
  //                    Reference Section
  // -----------------------------------------------------------

  insertReferenceSectionElement() {
    if (this.isEditorRunning) {
      this.editionManager?.insertReferenceSectionElement();
    }
  }

  updateReferenceSectionElement() {
    if (this.isEditorRunning && this.DataManager?.citations) {
      return this.DataManager.citations.updateReferenceSection();
    }
    return Promise.reject();
  }

  // ------------------------------------------------------
  //                        BREAK
  // ------------------------------------------------------
  insertPageBreak() {
    if (this.isEditorRunning && this.isWebLayout) {
      this.editionManager?.insertPageBreak();
    }
  }

  insertColumnBreak() {
    if (this.isEditorRunning) {
      this.editionManager?.insertColumnBreak();
    }
  }

  insertSectionBreak(type: Editor.Data.Sections.SectionType) {
    if (this.isEditorRunning) {
      this.editionManager?.insertSectionBreak(type);
    }
  }

  async saveSectionProperties(
    sectionId: string,
    properties: Pick<Editor.Data.Sections.Properties, 'p_o' | 'mar' | 'sz' | 't'>,
    options?: Editor.Data.Sections.UpdateOptions,
  ) {
    if (this.isEditorRunning && this.DataManager?.sections) {
      return this.DataManager.sections
        .updateSectionProperties(sectionId, properties, options)
        .then(() => {
          this.restore();
          this.debounceSelectionChanged();
        });
    }
  }

  /**
   * @deprecated use redux state.editor.status.selection.SECTION
   */
  getCurrentSectionAttributes() {
    if (this.isEditorRunning) {
      this.restore();

      let range = EditorSelectionUtils.getRange();
      if (range) {
        const pageNode = EditorDOMUtils.getContentContainer(range.startContainer);

        if (range.startContainer === pageNode) {
          EditorSelectionUtils.fixSelection(range);
        }

        const block = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.startContainer);

        let blockId: string | null = null;
        if (block instanceof Element) {
          blockId = block.id;
          if (block.hasAttribute('enclosed_element')) {
            blockId = block.getAttribute('enclosed_element');
          }
        }

        if (blockId) {
          const sectionId = this.DataManager?.sections?.getSectionOfBlock(blockId);
          if (sectionId) {
            const properties = {
              sectionId,
              properties: this.DataManager?.sections?.getSectionAttributes(sectionId),
              template: this.DataManager?.templates.getPageMeasures(),
              numberOfSections: this.DataManager?.sections?.numberOfSections || 0,
            };

            return properties;
          }
        }
      }
    }
    return {};
  }

  // ------------------------------------------------------
  // ------------------------------------------------------

  getPropertiesFromTOCSection(blockId: string, tocId: string) {
    if (this.isEditorRunning) {
      return this.DataManager?.tableOfContents.getPropertiesFromTOCSection(blockId, tocId) || null;
    }
    return null;
  }

  getPropertiesFromTOLSection(blockId: string, tolId: string) {
    if (this.isEditorRunning) {
      return this.DataManager?.tableOfContents.getPropertiesFromTOLSection(blockId, tolId) || null;
    }
    return null;
  }

  updateTOCSection(blockId: string, tocId: string, options?: TableOfContents.TOCSectionProperties) {
    if (this.isEditorRunning && this.editionManager instanceof EditionManager) {
      return this.editionManager.updateTOCSection(blockId, tocId, options);
    }
    return null;
  }

  insertTableOfContents(options: TableOfContents.TOCSectionProperties) {
    if (this.isEditorRunning) {
      this.editionManager?.insertTableOfContents(options);
    }
  }

  insertTableOfLabels(options: TableOfContents.TOLSectionProperties) {
    if (this.isEditorRunning) {
      this.editionManager?.insertTableOfLabels(options);
    }
  }

  updateTableOfLabels(
    blockId: string,
    tocId: string,
    options?: TableOfContents.TOLSectionProperties,
  ) {
    if (this.isEditorRunning && this.editionManager instanceof EditionManager) {
      return this.editionManager.updateTableOfLabels(blockId, tocId, options);
    }
    return null;
  }

  insertListOfFigures() {
    if (this.isEditorRunning) {
      this.editionManager?.insertListOfFigures();
    }
  }

  insertListOfTables() {
    if (this.isEditorRunning) {
      this.editionManager?.insertListOfTables();
    }
  }

  insertKeywords() {
    if (this.isEditorRunning) {
      this.editionManager?.insertKeywordsElement();
    }
  }

  insertAuthors() {
    if (this.isEditorRunning) {
      this.editionManager?.insertAuthorsElement();
    }
  }

  scrollIntoView(level0Id: string, elementId?: string) {
    return new Promise((resolve, reject) => {
      if (this.isEditorRunning && this.navigationManager) {
        this.navigationManager
          ?.renderDocumentAtId(level0Id, elementId, { position: 'INSIDE_END' })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  getSelectedLevel0Nodes(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ): string[] {
    if (this.isEditorRunning && this.selectionManager) {
      return EditorSelectionUtils.getSelectedLevel0NodesId(range);
    }
    return [];
  }

  restore() {
    if (this.isEditorRunning) {
      // do not trigger selection changes while restoring selection
      const editable = ReduxInterface.getEditorState().status.editable;
      if (editable) {
        this.visualizerManager?.selection.stopSelectionTracker();
      }

      try {
        this.DataManager?.selection?.restore();
      } catch (error) {
        Logger.error(error);
      }

      if (editable) {
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  debounceSelectionChanged() {
    if (this.isEditorRunning && this.editorContext.visualizerManager) {
      this.editorContext.visualizerManager.selection.debounceSelectionChanged();
    }
  }

  focusCitation(location: any) {
    if (this.isEditorRunning) {
      this.navigationManager?.renderDocumentAtId(location.level0, location.citation, {
        position: 'INSIDE_END',
      });
    }
  }

  // ---------------------------------------------------------------
  //                        COMMENTS
  // ---------------------------------------------------------------

  async createCommentWithOutTemp(initialContent: Descendant[]) {
    if (this.isEditorRunning && this.DataManager) {
      const tempCommentReference = EditorDOMUtils.generateUUID();
      const jsonRange = EditorSelectionUtils.getJsonRange()?.serializeToRangeData();

      if (jsonRange) {
        await this.DataManager.comments.addTemporaryComment(tempCommentReference, [jsonRange]);

        const result: any = await this.DataManager?.comments?.addComment(
          initialContent,
          tempCommentReference,
        );

        ReduxInterface.cancelTemporaryComment();
        return result.id;
      }
    }
  }

  async addComment(comment: Descendant[]) {
    if (this.isEditorRunning && this.editionManager) {
      this.editionManager.handleAddComment(comment);
    }
  }

  async addTemporaryComment() {
    if (this.isEditorRunning && this.editionManager) {
      ReduxInterface.completeAction('editor_comments_startCommentCreation');
      return this.editionManager.handleAddTemporaryComment();
    }
  }

  removeTemporaryComment() {
    if (this.isEditorRunning && this.editionManager) {
      this.editionManager.handleRemoveTemporaryComment();
    }
  }

  filterComments(filtered: string[]) {
    const allComments = Object.keys(ReduxInterface.getCommentsState().comments);

    // temp solution
    if (this.isEditorRunning && this.editorContext.stylesHandler) {
      const queryString = this.editorContext.stylesHandler.getElementRefQuery(allComments);
      let elements: Array<Element> = [];
      if (queryString && queryString.length > 0) {
        elements = Array.from(document.querySelectorAll(queryString));
      }

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element instanceof CommentElement || element instanceof TemporaryCommentElement) {
          if (element.elementReference && filtered.includes(element.elementReference)) {
            element.setHidden(false);
          } else {
            element.setHidden(true);
          }
        }
      }
    }
  }

  async focusComment(commentId: string) {
    if (this.isEditorRunning) {
      let level0Id, elementId;
      const locations = this.DataManager?.comments?.getCommentLocations(commentId);

      if (locations && locations.length > 0) {
        if (level0Id == null) {
          level0Id = locations[0].level0;
        }
        if (elementId == null) {
          elementId = locations[0].elementId;
        }
      }
      if (level0Id) {
        await this.navigationManager?.renderDocumentAtId(level0Id, elementId, {
          position: 'INSIDE_END',
        });
      }
    }
  }

  async editComment(commentId: string, content: Descendant[]) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.editComment(commentId, content);
    }
  }

  async replyComment(commentId: string, content: Descendant[]) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.replyComment(commentId, content);
    }
  }

  async voteComment(commentId: string, vote: boolean) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.voteComment(commentId, vote);
    }
  }

  async editReply(commentId: string, replyId: string, content: Descendant[]) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.editReply(commentId, replyId, content);
    }
  }

  async voteReply(commentId: string, replyId: string, vote: boolean) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.voteReply(commentId, replyId, vote);
    }
  }

  async resolveComment(commentId: string) {
    if (this.isEditorRunning && this.DataManager) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        await this.DataManager.comments.resolveComment(commentId);

        this.restore();
        this.debounceSelectionChanged();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }
  }

  async resolveAllComments(
    selectedComments: { tag?: string; ref: string }[],
    forceAll: boolean = false,
  ) {
    const result = {
      selectionCollapsed: false,
    };

    if (this.isEditorRunning && this.DataManager) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        // selection fixes
        if (range) {
          let closestTracked;
          if (range.collapsed) {
            closestTracked = EditorDOMUtils.closest(range.commonAncestorContainer, [
              ELEMENTS.CommentElement.TAG,
              ELEMENTS.TemporaryComment.TAG,
            ]);
          } else {
            closestTracked = EditorDOMUtils.closest(range.startContainer, [
              ELEMENTS.CommentElement.TAG,
              ELEMENTS.TemporaryComment.TAG,
            ]);
          }

          if (closestTracked instanceof Element) {
            let referenceElement = closestTracked;
            while (
              referenceElement.previousSibling instanceof Element &&
              referenceElement.previousSibling.getAttribute('element_reference') ===
                referenceElement.getAttribute('element_reference')
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              range.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              range.setCaret(referenceElement, 'PRE');
            }
          } else {
            range.collapse(true);
          }

          EditorSelectionUtils.applyRangeToSelection(range);
          this.visualizerManager?.selection.triggerSelectionChanged();
        }

        if (forceAll) {
          await this.DataManager.comments.resolveAllComments();
          result.selectionCollapsed = true;
        } else if (selectedComments?.length) {
          await this.DataManager.comments.resolveAllComments(
            selectedComments.map((element) => element.ref),
          );
          result.selectionCollapsed = true;
        }
      } catch (error) {
        Logger.captureException(error);
        throw error;
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }

    return result;
  }

  async deleteComment(commentId: string) {
    if (this.isEditorRunning && this.DataManager) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        await this.DataManager.comments.deleteComment(commentId);

        this.restore();
        this.debounceSelectionChanged();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }
  }

  async deleteAllComments(
    selectedComments: { tag?: string; ref: string }[],
    forceAll: boolean = false,
  ) {
    const result = {
      selectionCollapsed: false,
    };

    if (this.isEditorRunning && this.DataManager) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        // selection fixes
        if (range) {
          let closestTracked;
          if (range.collapsed) {
            closestTracked = EditorDOMUtils.closest(range.commonAncestorContainer, [
              ELEMENTS.CommentElement.TAG,
              ELEMENTS.TemporaryComment.TAG,
            ]);
          } else {
            closestTracked = EditorDOMUtils.closest(range.startContainer, [
              ELEMENTS.CommentElement.TAG,
              ELEMENTS.TemporaryComment.TAG,
            ]);
          }

          if (closestTracked instanceof Element) {
            let referenceElement = closestTracked;
            while (
              referenceElement.previousSibling instanceof Element &&
              referenceElement.previousSibling.getAttribute('element_reference') ===
                referenceElement.getAttribute('element_reference')
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              range.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              range.setCaret(referenceElement, 'PRE');
            }
          } else {
            range.collapse(true);
          }

          EditorSelectionUtils.applyRangeToSelection(range);
          this.visualizerManager?.selection.triggerSelectionChanged();
        }

        if (forceAll) {
          await this.DataManager.comments.deleteAllComments();
          result.selectionCollapsed = true;
        } else if (selectedComments?.length) {
          await this.DataManager.comments.deleteAllComments(
            selectedComments.map((element) => element.ref),
          );
          result.selectionCollapsed = true;
        }
      } catch (error) {
        Logger.captureException(error);
        throw error;
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }
    return result;
  }

  deleteReply(commentId: string, replyId: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.deleteReply(commentId, replyId);
    }
  }

  async changeCommentPriority(commentId: string, priority: Comments.CommentPriority) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.comments.changeCommentPriority(commentId, priority);
    }
  }

  // ---------------------------------------------------------------
  //                TRACKED ACTIONS / SUGGESTIONS
  // ---------------------------------------------------------------
  filterSuggestions(filtered: string[]) {
    const allSuggestions = Object.keys(ReduxInterface.getTrackedActionsState().byId);
    // temp solution
    if (this.isEditorRunning && this.editorContext.stylesHandler) {
      const queryString = this.editorContext.stylesHandler.getElementRefQuery(allSuggestions);
      let elements: Array<Element> = [];
      if (queryString && queryString.length > 0) {
        elements = Array.from(document.querySelectorAll(queryString));
      }

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element instanceof TrackInsertElement || element instanceof TrackDeleteElement) {
          if (element.elementReference && filtered.includes(element.elementReference)) {
            element.setHidden(false);
          } else {
            element.setHidden(true);
          }
        }
      }
    }
  }

  setSelectionInTrackedAction(element: HTMLElement) {
    return this.isEditorRunning && this.selectionManager?.setCaret(element, 'INDEX', 0);
  }

  async acceptAllTrackedActions(
    selectedTrackedActions: { tag?: string; ref: string }[],
    forceAll: boolean = false,
  ) {
    const result = {
      selectionCollapsed: false,
    };

    if (this.isEditorRunning && this.DataManager) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        // selection fixes
        if (range) {
          let closestTracked;
          if (range.collapsed) {
            closestTracked = EditorDOMUtils.closest(range.commonAncestorContainer, [
              ELEMENTS.TrackInsertElement.TAG,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);
          } else {
            closestTracked = EditorDOMUtils.closest(range.startContainer, [
              ELEMENTS.TrackInsertElement.TAG,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);
          }

          if (closestTracked instanceof Element) {
            let referenceElement = closestTracked;
            while (
              referenceElement.previousSibling instanceof Element &&
              referenceElement.previousSibling.getAttribute('element_reference') ===
                referenceElement.getAttribute('element_reference')
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              range.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              range.setCaret(referenceElement, 'PRE');
            }
          } else {
            range.collapse(true);
          }

          EditorSelectionUtils.applyRangeToSelection(range);
          this.visualizerManager?.selection.triggerSelectionChanged();
        }

        ReduxInterface.startEditorLoading();
        // accept tracked actions
        if (forceAll) {
          await this.DataManager?.suggestions?.acceptAllTrackedActions([]);
          result.selectionCollapsed = true;
        } else if (selectedTrackedActions.length) {
          await this.DataManager?.suggestions?.acceptAllTrackedActions(
            selectedTrackedActions.map((element) => element.ref),
          );
          result.selectionCollapsed = true;
        }
        ReduxInterface.stopEditorLoading();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }

    return result;
  }

  async rejectAllTrackedActions(
    selectedTrackedActions: { tag?: string; ref: string }[],
    forceAll: boolean = false,
  ) {
    const result = {
      selectionCollapsed: false,
    };

    if (this.isEditorRunning) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        // selection fixes
        if (range) {
          let closestTracked;
          if (range.collapsed) {
            closestTracked = EditorDOMUtils.closest(range.commonAncestorContainer, [
              ELEMENTS.TrackInsertElement.TAG,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);
          } else {
            closestTracked = EditorDOMUtils.closest(range.startContainer, [
              ELEMENTS.TrackInsertElement.TAG,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);
          }

          if (closestTracked instanceof Element) {
            let referenceElement = closestTracked;
            while (
              referenceElement.previousSibling instanceof Element &&
              referenceElement.previousSibling.getAttribute('element_reference') ===
                referenceElement.getAttribute('element_reference')
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              range.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              range.setCaret(referenceElement, 'PRE');
            }
          } else {
            range.collapse(true);
          }

          EditorSelectionUtils.applyRangeToSelection(range);
          this.visualizerManager?.selection.triggerSelectionChanged();
        }

        // reject tracked actions
        ReduxInterface.startEditorLoading();
        if (forceAll) {
          await this.DataManager?.suggestions?.rejectAllTrackedActions([]);
          result.selectionCollapsed = true;
        } else if (selectedTrackedActions.length) {
          await this.DataManager?.suggestions?.rejectAllTrackedActions(
            selectedTrackedActions.map((element) => element.ref),
          );
          result.selectionCollapsed = true;
        }
        ReduxInterface.stopEditorLoading();
        this.visualizerManager?.selection.triggerSelectionChanged();
      } catch (error) {
        Logger.captureException(error);
        throw error;
      } finally {
        this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    } else {
      throw new Error('Editor not running!');
    }

    return result;
  }

  replyTrackedAction(id: string, content: Descendant[]) {
    if (this.isEditorRunning) {
      //@ts-expect-error RichText: content shouldn't be a string
      this.DataManager?.suggestions?.replyTrackedAction(id, content);
    }
    return null;
  }

  async focusTrackedAction(id: string, callback?: () => void) {
    if (this.isEditorRunning && this.DataManager) {
      const locations = this.DataManager.suggestions?.getTrackedActionLocation(id);
      let location: any = {};
      if (locations && locations.length > 0) {
        for (let i = 0; i < locations.length; i++) {
          location = locations[i];

          try {
            await this.navigationManager?.renderDocumentAtId(location.level0, location.elementId, {
              position: 'INSIDE_END',
            });
            break;
          } catch (error) {
            Logger.captureException(error);
          }
        }
      } else if (this.editorContext?.documentContainer) {
        location.level0 = EditorDOMUtils.findFirstLevelChildNode(
          this.editorContext?.documentContainer,
          document.querySelector(`*[element_reference="${id}"]`),
        );

        await this.navigationManager?.renderDocumentAtId(location.level0, location.elementId, {
          position: 'INSIDE_END',
        });
      }

      if (callback) {
        callback();
      }
    }
  }

  voteTrackedAction(trackedActionId: string, vote: any) {
    if (this.isEditorRunning) {
      this.DataManager?.suggestions?.voteTrackedAction(trackedActionId, vote);
    }
  }

  voteTrackedActionReply(trackedActionId: string, replyId: string, vote: any) {
    if (this.isEditorRunning) {
      this.DataManager?.suggestions?.voteTrackedActionReply(trackedActionId, replyId, vote);
    }
  }

  editTrackedActionReply(trackedActionId: string, replyId: string, newContent: Descendant[]) {
    if (this.isEditorRunning) {
      //@ts-expect-error RichText: content shouldn't be a string
      this.DataManager?.suggestions?.editTrackedActionReply(trackedActionId, replyId, newContent);
    }
  }

  deleteTrackedActionReply(trackedActionId: string, replyId: string) {
    if (this.isEditorRunning) {
      this.DataManager?.suggestions?.deleteTrackedActionReply(trackedActionId, replyId);
    }
  }

  acceptTrackedAction(trackedActionId: string) {
    if (this.isEditorRunning) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        if (range) {
          const closestTracked: Node | null = EditorDOMUtils.closest(
            range.commonAncestorContainer,
            [ELEMENTS.TrackInsertElement.TAG, ELEMENTS.TrackDeleteElement.TAG],
          );

          if (
            closestTracked instanceof HTMLElement &&
            closestTracked.getAttribute('element_reference') === trackedActionId
          ) {
            let referenceElement: HTMLElement = closestTracked;

            while (
              referenceElement.previousSibling &&
              referenceElement.previousSibling.nodeType === Node.ELEMENT_NODE &&
              (referenceElement.previousSibling as HTMLElement).getAttribute(
                'element_reference',
              ) === trackedActionId
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              this.selectionManager?.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              this.selectionManager?.setCaret(referenceElement, 'PRE');
            }
          }

          this.DataManager?.suggestions?.acceptTrackedAction(trackedActionId);

          this.visualizerManager?.selection.triggerSelectionChanged();
        }
      } finally {
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  rejectTrackedAction(trackedActionId: string) {
    if (this.isEditorRunning) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.editionManager?.removePasteOptions();

        const range = EditorSelectionUtils.getRange();

        if (range) {
          const closestTracked = EditorDOMUtils.closest(range.commonAncestorContainer, [
            ELEMENTS.TrackInsertElement.TAG,
            ELEMENTS.TrackDeleteElement.TAG,
          ]) as HTMLElement;

          if (
            closestTracked &&
            closestTracked.getAttribute('element_reference') === trackedActionId
          ) {
            let referenceElement = closestTracked;

            while (
              referenceElement.previousSibling &&
              referenceElement.previousSibling.nodeType === Node.ELEMENT_NODE &&
              (referenceElement.previousSibling as HTMLElement).getAttribute(
                'element_reference',
              ) === trackedActionId
            ) {
              referenceElement = referenceElement.previousSibling as HTMLElement;
            }

            if (referenceElement.previousSibling) {
              this.selectionManager?.setCaret(referenceElement.previousSibling, 'INSIDE_END');
            } else {
              this.selectionManager?.setCaret(referenceElement, 'PRE');
            }
          }

          this.DataManager?.suggestions?.rejectTrackedAction(trackedActionId);

          this.visualizerManager?.selection.triggerSelectionChanged();
        }
      } finally {
        this.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  changeTrackedActionPriority(trackedActionId: string, priority: string) {
    if (this.isEditorRunning) {
      this.DataManager?.suggestions?.changeTrackedActionPriority(
        trackedActionId,
        priority as SuggestionDataPriority,
      );
    }
  }

  // ---------------------------------------------------------------
  //                        HYPERLINK
  // ---------------------------------------------------------------
  getLinkData() {
    if (this.isEditorRunning && this.selectionManager) {
      this.restore();
      return EditorSelectionUtils.getLinkData();
    }
    return null;
  }

  openHyperlink() {
    if (this.isEditorRunning) {
      this.restore();

      const data = this.getLinkData();

      if (data?.url) {
        const urlIsExternal =
          data.url.replace('http://', '').replace('https://', '').split('/')[0].toLowerCase() !==
          window.location.host
            .replace('http://', '')
            .replace('https://', '')
            .split('/')[0]
            .toLowerCase();

        if (urlIsExternal) {
          ReduxInterface.openAndUpdateModal({
            modal: 'OpenHyperlinkModal',
            data: {
              url: data.url,
            },
          });
        } else {
          window.open(data.url, '_blank');
        }
      }
    }
  }

  editHyperlink() {
    if (this.isEditorRunning) {
      const data = this.getLinkData();
      ReduxInterface.openAndUpdateModal({
        modal: 'HyperlinkModal',
        data: {
          ...data,
          edit: true,
        },
      });
    }
  }

  insertHyperlink(links: string[], url: string, showTextToDisplay: boolean, textToDisplay: string) {
    if (this.isEditorRunning) {
      this.editionManager?.handleInsertHyperlink(links, url, showTextToDisplay, textToDisplay);
    }
  }

  deleteHyperlink() {
    if (this.isEditorRunning) {
      this.editionManager?.handleDeleteHyperlink();
    }
  }

  // ---------------------------------------------------------------
  //                    UNDO / REDO
  // ---------------------------------------------------------------

  /**
   * undo listener
   */
  undoListener() {
    if (this.isEditorRunning && this.editionManager && this.isWebLayout) {
      if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        this.editionManager.undo();
      } else {
        this.editionManager.removePasteOptions();

        HistoryManager.getInstance().undo();
      }
    }
  }

  /**
   * redo listener
   */
  redoListener() {
    if (this.isEditorRunning && this.isWebLayout) {
      if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        this.editionManager.redo();
      } else {
        this.editionManager?.removePasteOptions();

        HistoryManager.getInstance().redo();
      }
    }
  }

  canUndo() {
    if (this.isEditorRunning) {
      return HistoryManager.getInstance().canUndo();
    }
    return false;
  }

  canRedo() {
    if (this.isEditorRunning) {
      return HistoryManager.getInstance().canRedo();
    }
    return false;
  }

  // ---------------------------------------------------------------
  //                        COPY / PASTE
  // ---------------------------------------------------------------
  removePasteOptions() {
    return this.isEditorRunning && this.editionManager?.removePasteOptions();
  }

  updatePasteOptions() {
    return this.isEditorRunning && this.editionManager?.updatePasteOptions();
  }

  handleCutMenuOption() {
    if (this.isEditorRunning) {
      if (this.selectionManager && !this.selectionManager.isSelectionInPage()) {
        this.restore();
      }

      document.execCommand('cut');
    }
  }

  handleCopyMenuOption() {
    if (this.isEditorRunning) {
      if (this.selectionManager && !this.selectionManager.isSelectionInPage()) {
        this.restore();
      }

      document.execCommand('copy');
    }
  }

  handlePasteMenuOption() {
    if (this.isEditorRunning) {
      if (this.selectionManager && !this.selectionManager.isSelectionInPage()) {
        this.restore();
      }

      ExtensionHelper.triggerPasteEvent(ReduxInterface.getPlatformInfo());
    }
  }

  handlePasteOptions(option: Editor.Clipboard.PasteOptions) {
    return this.isEditorRunning && this.editionManager?.handlePasteOptions(option);
  }

  // ------------------------------------------------------
  //                        EQUATIONS
  // ------------------------------------------------------
  insertEquation(value: any) {
    if (this.isEditorRunning) {
      return this.editionManager?.handleInsertEquation(value);
    }
  }

  editEquation() {
    if (this.isEditorRunning) {
      return this.editionManager?.handleEditEquation();
    }
  }

  updateEquation(blockId: string, elementId: string, value: any) {
    if (this.isEditorRunning) {
      return this.editionManager?.handleUpdateEquation(blockId, elementId, value);
    }
  }

  // ------------------------------------------------------
  //                        VERSIONS
  // ------------------------------------------------------
  openVersionHistory() {
    if (this.isEditorRunning) {
      ReduxInterface.openVersionHistory();
      this.disableEditionCapabilities();
    }
  }

  openSaveVersion() {
    if (this.isEditorRunning) {
      ReduxInterface.openAndUpdateModal({
        modal: 'SaveVersionModal',
        data: {
          type: 'saveVersion',
        },
      });
    }
  }

  saveVersion(description: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.versions.saveVersion(description);
    }
    return Promise.resolve();
  }

  editVersionDescription(description: string, index: any) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.versions.editVersionDescription(description, index);
    }
    return Promise.resolve();
  }

  async loadVersion(index: any) {
    if (this.isEditorRunning) {
      ReduxInterface.startEditorLoading();
      await this.DataManager?.versions.loadVersion(index);
      ReduxInterface.setLoadVersion(index);
      ReduxInterface.stopEditorLoading();
    }
  }

  async restoreVersion(index: any) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.versions.restoreVersion(index);
    }
  }

  backToCurrentVersion() {
    if (this.isEditorRunning) {
      this.DataManager?.versions.backToCurrentVersion();
      this.setEditionMode();
      ReduxInterface.setLoadVersion(null);
    }
    return null;
  }

  // ------------------------------------------------------
  //                   Spell Check
  // ------------------------------------------------------
  async runSpellCheckFromStart() {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.runSpellCheckFromStart();
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async findError(forward: boolean) {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.findError(forward);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async recheckError(rangeData: Editor.SpellCheck.Occurrence['rangeData'], wordToRestore: string) {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.recheckError(rangeData, wordToRestore);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  endSpellcheckCycle() {
    return this.isEditorRunning && this.spellCheck?.endSpellcheckCycle();
  }

  async replaceWord(occurence: Editor.SpellCheck.Occurrence, suggestionToReplace: string) {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.replaceWord(occurence, suggestionToReplace);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async ignoreErrorOnce() {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.ignoreErrorOnce();
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async ignoreAllOccurrences(term: string, lang: string) {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.ignoreAllOccurrences(term, lang);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async changeAllOcurrences(wordToReplace: string, suggestionToReplace: string) {
    if (this.isEditorRunning && this.spellCheck) {
      return this.spellCheck.changeAllOcurrences(wordToReplace, suggestionToReplace);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async addWordToDictionary(term: string, lang: string) {
    if (this.isEditorRunning) {
      return this.spellCheck?.addWordToDictionary(term, lang);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  restoreSelectionToError(markerToRestore: any, wordToRestore: string) {
    return (
      this.isEditorRunning &&
      this.spellCheck?.restoreSelectionToError(markerToRestore, wordToRestore)
    );
  }

  getSpellCheckDefaultLanguage() {
    return this.isEditorRunning && this.DataManager?.spellcheck?.getDefaultLanguage();
  }

  getSpellCheckLanguages() {
    return (this.isEditorRunning && this.DataManager?.spellcheck?.languages) || [];
  }

  getLanguageOnSelection() {
    let obj = {};
    if (this.isEditorRunning) {
      if (this.navigationManager?.isMarkerRendered()) {
        this.navigationManager?.scrollIntoSelection();
        EditorSelectionUtils.fixSelection();

        const nodeIds = EditorSelectionUtils.getSelectedLevel0NodesId() as string[];
        obj = this.getLanguageForNodes(nodeIds);
      }
    }

    return obj;
  }

  getLanguageForNodes(nodeIds: string[]) {
    let languages = {};

    if (this.isEditorRunning) {
      languages = this.DataManager?.nodes?.getLanguageForNodes(nodeIds);
    }

    return languages;
  }

  async setLanguageOnDocument(language: string) {
    if (this.isEditorRunning) {
      this.DataManager?.document?.setDocumentLanguage(language).then(() => {
        // Force restore selection lifecycle
        this.restore();
        this.debounceSelectionChanged();
      });
    }
  }

  async setLanguageOnNodes(language: string, nodeIds: string[]) {
    if (this.isEditorRunning) {
      await this.DataManager?.nodes?.setLanguageOnNodes(language, nodeIds);

      // Force restore selection lifecycle
      this.restore();
      this.debounceSelectionChanged();

      ReduxInterface.setSpellcheckCurrLang();
    }
  }

  // ------------------------------------------------------
  //                   Find and Replace
  // ------------------------------------------------------
  openFindAndReplace() {
    ReduxInterface.setFindAndReplaceSelectedText(this.getSelectedText() || '');
    ReduxInterface.setSidebarView('FIND_AND_REPLACE');
  }

  async findMatch(textToFind: string, matchCase: boolean, wholeWords: boolean) {
    if (this.isEditorRunning) {
      this.editorContext.findAndReplace = new FindAndReplace(this.editorContext);
      return this.editorContext.findAndReplace.findInDocument(textToFind, matchCase, wholeWords);
    } else {
      //TODO: show general error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async replaceMatch(newTerm: string, matchCase: boolean, wholeWords: boolean, all: boolean) {
    if (this.isEditorRunning && this.editorContext.findAndReplace != null) {
      return await this.editorContext.findAndReplace.replaceInDocument(
        newTerm,
        matchCase,
        wholeWords,
        all,
      );
    } else {
      //TODO: show find no initialized error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async findNext() {
    if (this.isEditorRunning && this.editorContext.findAndReplace != null) {
      const res = await this.editorContext.findAndReplace.nextMatch();
      ReduxInterface.setFindAndReplaceCurrentResult({
        currentResult: res.currentMatchIndex,
        currentCanBeReplaced: res.editable,
      });
    } else {
      //TODO: show find no initialized error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async findPrevious() {
    if (this.isEditorRunning && this.editorContext.findAndReplace != null) {
      const res = await this.editorContext.findAndReplace.previousMatch();
      ReduxInterface.setFindAndReplaceCurrentResult({
        currentResult: res.currentMatchIndex,
        currentCanBeReplaced: res.editable,
      });
    } else {
      //TODO: show find no initialized error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  async navigateToMatch(matchIndex: number) {
    if (this.isEditorRunning && this.editorContext.findAndReplace != null) {
      const res = await this.editorContext.findAndReplace.navigateToMatch(matchIndex);
      ReduxInterface.setFindAndReplaceCurrentResult({
        currentResult: res.currentMatchIndex,
        currentCanBeReplaced: res.editable,
      });
    } else {
      //TODO: show find no initialized error
      Logger.captureException(new Error('Services not initialized!'));
    }
  }

  // ------------------------------------------------------
  //                   Tasks
  // ------------------------------------------------------
  openCreateTaskOverlay() {
    if (this.isEditorRunning && this.editorContext.DataManager?.selection) {
      const rangeData = this.editorContext.DataManager.selection.history.last[0];

      const node = document.getElementById(rangeData.start.b);

      const offsets = EditorDOMUtils.getOffsets(node);

      // TODO:
      // dismiss comments, suggestions and tasks

      ReduxInterface.setTaskOverlayData({ offsets, operation: 'create' });
      ReduxInterface.completeAction('editor_tasks_startTaskCreation');
    }
  }

  async createTask(taskData: TaskDataLongProps) {
    if (this.isEditorRunning) {
      if (this.navigationManager?.isMarkerRendered()) {
        this.visualizerManager?.selection.stopSelectionTracker();
        this.navigationManager?.scrollIntoSelection();
        const locations = EditorSelectionUtils.getSelectedLevel0NodesId() as string[];
        const data = await this.DataManager?.tasks?.createTask(taskData, locations);
        this.visualizerManager?.selection.debounceSelectionChanged();
        this.visualizerManager?.selection.debounceStartSelectionTracker();
        return data;
      }
    }
  }

  async changeStatus(id: string, status: StatusValue) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.changeStatus(id, status);
    }
  }

  async changeAssignee(id: string, user: any) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.changeAssignee(id, user);
    }
  }

  async changeDescription(id: string, description: string) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.changeDescription(id, description);
    }
  }

  async editTask(id: string, taskData: Partial<TaskDataLongProps>) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.editTask(id, taskData);
    }
  }

  async watchTask(id: string, user: string) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.watchTask(id, user);
    }
  }

  async removeWatchFromTask(id: string, user: string) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.removeWatchFromTask(id, user);
    }
  }

  async deleteTask(id: string) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.deleteTask(id).then(() => {
        this.restore();
        this.debounceSelectionChanged();
      });
    }
  }

  async replyTask(id: string, replyContent: Descendant[]) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.replyTask(id, replyContent);
    }
  }

  async editTaskReply(taskId: string, taskReplyId: string, content: Descendant[]) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.editTaskReply(taskId, taskReplyId, content);
    }
  }

  async voteTaskReply(taskId: string, taskReplyId: string, vote: boolean) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.voteTaskReply(taskId, taskReplyId, vote);
    }
  }

  async deleteTaskReply(taskId: string, taskReplyId: string) {
    if (this.isEditorRunning && this.DataManager?.tasks) {
      return this.DataManager.tasks.deleteTaskReply(taskId, taskReplyId);
    }
  }

  focusTask(id: string) {
    if (this.isEditorRunning) {
      const locations = this.DataManager?.tasks?.getNodesWithTask(id);
      let location: Node | string | null = null;
      if (locations && locations.length > 0) {
        location = locations[0];
      } else if (this.editorContext?.documentContainer) {
        location = EditorDOMUtils.findFirstLevelChildNode(
          this.editorContext.documentContainer,
          document.querySelector(`*[element_reference="${id}"]`),
        );
      }

      let locationId: string | null = null;

      if (location instanceof HTMLElement) {
        locationId = location.id;
      } else if (typeof location === 'string') {
        locationId = location;
      }

      if (locationId) {
        this.navigationManager?.renderDocumentAtId(locationId);
      }
    }
  }

  // ------------------------------------------------------
  //                  Document Styles
  // ------------------------------------------------------
  async createNewDocumentStyleFromId(baseStyleKey: string) {
    if (this.isEditorRunning && this.stylesHandler) {
      await this.stylesHandler.createNewDocumentStyleFromId(baseStyleKey);
    }
  }

  createNewDocumentStyle(id: string, newStyle: Partial<DocumentStyleData>) {
    if (this.isEditorRunning && this.stylesHandler) {
      this.stylesHandler.createNewDocumentStyle(id, newStyle);
    }
  }

  async applyDocumentStyleToSelection(data: { style: string }) {
    if (this.isEditorRunning && this.stylesHandler && this.isWebLayout) {
      await this.stylesHandler.applyDocumentStyleToSelection(data.style);
    }
  }

  async updateDocumentStyleFromSelection(styleId: string) {
    if (this.isEditorRunning && this.stylesHandler) {
      await this.stylesHandler.updateDocumentStyleFromSelection(styleId);
    }
  }

  renameDocumentStyle(styleId: string, newName: string) {
    if (this.isEditorRunning && this.stylesHandler) {
      this.stylesHandler.renameDocumentStyle(styleId, newName);
    }
  }

  deleteDocumentStyle(styleId: string) {
    return this.isEditorRunning && this.stylesHandler?.deleteDocumentStyle(styleId);
  }

  resetTemplateStyle(styleId: string) {
    return this.isEditorRunning && this.stylesHandler?.resetTemplateStyle(styleId);
  }

  isStyleFromTemplate(styleId: string) {
    return this.isEditorRunning && this.DataManager?.styles?.isStyleFromTemplate(styleId);
  }

  indentCurrentSelection(indentation: Editor.Elements.IndentationProperties) {
    if (this.isEditorRunning) {
      if (this.editionVersion === 1) {
        return this.stylesHandler?.indentationCurrentSelection(indentation);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        return this.editionManager.indentCurrentSelection(indentation);
      }
    }
    return null;
  }

  toggleList(styleId?: string | boolean) {
    return this.isEditorRunning && this.stylesHandler?.toggleListStyleToSelection(styleId);
  }

  increaseIndent() {
    if (this.isEditorRunning && this.stylesHandler) {
      return this.stylesHandler.increaseIndent();
    }
    return null;
  }

  decreaseIndent() {
    if (this.isEditorRunning && this.stylesHandler) {
      return this.stylesHandler.decreaseIndent();
    }
    return null;
  }

  updateTemplateStyles(selectedTemplate: string, removeInlineStyles?: boolean) {
    if (this.isEditorRunning) {
      ReduxInterface.startEditorLoading('UPDATING_TEMPLATE');
      return this.DataManager?.templates?.setDocumentTemplate(selectedTemplate, removeInlineStyles);
    }
    return null;
  }

  async updateListStyle(styleId: string, style: any) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.styles.listStyles.updateListStyle(styleId, style);
    }
  }

  hasStyleAList(styleId: string): string | null {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.styles.documentStyles.hasStyleAList(styleId);
    }
    return null;
  }

  hasStyleIndentation(styleId: string) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.styles.documentStyles.hasStyleIndentation(styleId);
    }
    return null;
  }

  getStyleIdForList(listId: string): string | null {
    if (this.isEditorRunning && this.DataManager?.numbering) {
      return this.DataManager.numbering.getStyleIdForList(listId);
    }
    return null;
  }

  async createListStyle(style: any) {
    if (this.isEditorRunning && this.DataManager) {
      return this.DataManager.styles.listStyles.createListStyle(style);
    }
  }

  createListWithStyle(styleId: string) {
    return this.isEditorRunning && this.DataManager?.numbering?.createNewList(styleId);
  }

  validateFontFamily(font: string) {
    return this.isEditorRunning && this.fontFamilyHelper?.validateFontFamily(font);
  }

  applyParagraphBackgroundColor(value: string | null) {
    if (this.isEditorRunning && this.stylesHandler && this.editionManager) {
      if (this.editionVersion === 1) {
        return this.stylesHandler.applyParagraphBackgroundColor(value);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        return this.editionManager.applyParagraphBackgroundColor(value);
      }
    }
  }

  lineSpaceCurrentSelection(spacing: Editor.Elements.SpacingProperties) {
    if (this.isEditorRunning && this.stylesHandler && this.editionManager) {
      if (this.editionVersion === 1) {
        return this.stylesHandler.lineSpaceCurrentSelection(spacing);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        return this.editionManager.lineSpaceCurrentSelection(spacing);
      }
    }
  }

  setLinePaginationProperties(properties: Editor.Elements.PaginationProperties) {
    if (this.isEditorRunning && this.stylesHandler && this.editionManager) {
      if (this.editionVersion === 1) {
        return this.stylesHandler.setLinePaginationProperties(properties);
      } else if (this.editionVersion === 2 && this.editionManager instanceof EditionManager) {
        return this.editionManager.setLinePaginationProperties(properties);
      }
    }
  }

  getSelectedTableProperties() {
    if (this.isEditorRunning && this.stylesHandler) {
      return this.stylesHandler.getSelectedTableProperties();
    }

    return null;
  }

  setTableProperties(propertiesData: Editor.Styles.TablePropertiesData) {
    return this.isEditorRunning && this.editionManager?.handleUpdateTableProperties(propertiesData);
  }

  // eslint-disable-next-line camelcase
  getIndentationValuesForStyle(styleId: string) {
    let indentation: any = null;
    if (this.isEditorRunning) {
      const style = this.DataManager?.styles?.getDocumentStyleFromId(styleId);
      const styleProps = style?.extendedP;

      if (styleProps) {
        indentation = {
          sp_ind: {},
          ind: {},
        };

        let listLevelDefenition;
        if (styleProps?.lst?.lId) {
          const styleDefinition = this.DataManager?.numbering?.getDefinitionForList(
            styleProps.lst.lId,
          );
          listLevelDefenition = styleDefinition?.level(styleProps.lst.lLv);
        }

        // Special indentation type
        if (listLevelDefenition?.sp_ind?.t != null) {
          indentation.sp_ind.t = listLevelDefenition.sp_ind.t;
        }

        if (styleProps?.sp_ind?.t != null) {
          indentation.sp_ind.t = styleProps.sp_ind.t;
        }

        // Special indentation value
        if (listLevelDefenition?.sp_ind?.v != null) {
          indentation.sp_ind.v = listLevelDefenition.sp_ind.t;
        }

        if (styleProps?.sp_ind?.v != null) {
          indentation.sp_ind.v = styleProps.sp_ind.v;
        }

        // Left indentation
        if (listLevelDefenition?.ind?.l != null) {
          indentation.ind.l = listLevelDefenition.ind.l;
        }

        if (styleProps?.ind?.l != null) {
          indentation.ind.l = styleProps.ind.l;
        }

        // Right indentation
        if (listLevelDefenition?.ind?.r != null) {
          indentation.ind.r = listLevelDefenition.ind.r;
        }

        if (styleProps?.ind?.r != null) {
          indentation.ind.r = styleProps.ind.r;
        }
      }
    }
    return indentation;
  }
  /**
   * @deprecated use state.editor.toolbar.indentation
   */
  parseIndentationValuesForSelectedNodes() {
    if (this.isEditorRunning) {
      const selectedNodes = EditorSelectionUtils.getSelectedNodes() || [];
      const indentation: any = {
        leftIndentation: 0,
        rightIndentation: 0,
        specialIndent: '',
        specialIndentValue: 0,
      };
      for (let index = 0; index < selectedNodes.length; index += 1) {
        const node = selectedNodes[index];
        const block = EditorDOMUtils.closest(node, [
          ...EditorDOMElements.BLOCK_TEXT_ELEMENTS,
          'CAPTION',
          'FIGCAPTION',
          'IMAGE-ELEMENT',
          ELEMENTS.TableElement.TAG,
          'CAPTION', // legacy
          'FIGCAPTION', // legacy
          ELEMENTS.FigureElement.TAG, // legacy
        ]);
        if (block instanceof HTMLElement) {
          const style = this.DataManager?.styles?.getDocumentStyleFromId(
            block.dataset.styleId as string,
          );

          // get list defenition if exist
          let listLevelDefenition;
          if (this.DataManager?.numbering?.isListElement(block.id)) {
            listLevelDefenition = this.DataManager?.numbering?.getLevelDefinitionFromBlock(
              block.id,
            );
          }

          // Special indentation type
          if (block.dataset.specialIndent != null) {
            indentation.specialIndent = block.dataset.specialIndent;
          } else if (
            listLevelDefenition &&
            listLevelDefenition.sp_ind &&
            listLevelDefenition?.sp_ind?.t != null
          ) {
            indentation.specialIndent =
              //@ts-expect-error temporary fix for legacy issues
              listLevelDefenition.sp_ind.value?.t || listLevelDefenition.sp_ind.t;
          } else if (style && style.p && style.p.sp_ind && style.p.sp_ind.t != null) {
            indentation.specialIndent = style.p.sp_ind.t;
          }
          // Special indentation value
          if (block.dataset.specialIndentValue != null) {
            indentation.specialIndentValue = parseMeasurement(
              block.dataset.specialIndentValue,
              'cm',
              { defaultUnit: 'pt' },
            );
          } else if (style && style.p && style.p.sp_ind && style.p.sp_ind.v != null) {
            indentation.specialIndentValue = parseMeasurement(`${style.p.sp_ind.v}`, 'cm', {
              defaultUnit: 'pt',
            });
          } else if (
            listLevelDefenition &&
            listLevelDefenition.sp_ind &&
            listLevelDefenition.sp_ind.v != null
          ) {
            indentation.specialIndentValue = parseMeasurement(
              listLevelDefenition.sp_ind.v.toString(),
              'cm',
              {
                defaultUnit: 'pt',
              },
            );
          }

          // Left indentation
          if (block.dataset.leftIndentation != null) {
            indentation.leftIndentation = parseMeasurement(block.dataset.leftIndentation, 'cm', {
              defaultUnit: 'pt',
            });
          } else if (style && style.p && style.p.ind && style.p.ind.l != null) {
            indentation.leftIndentation = parseMeasurement(`${style.p.ind.l}`, 'cm', {
              defaultUnit: 'pt',
            });
          } else if (listLevelDefenition && listLevelDefenition.ind?.l != null) {
            indentation.leftIndentation = parseMeasurement(
              listLevelDefenition.ind.l.toString(),
              'cm',
              {
                defaultUnit: 'pt',
              },
            );
          }

          // decrement indentation if special indent exist
          if (
            indentation.specialIndentValue != null &&
            indentation.specialIndent === INDENT_TYPE.HANGING
          ) {
            indentation.leftIndentation -= indentation.specialIndentValue;
          }

          // Right indentation
          if (block.dataset.rightIndentation != null) {
            indentation.rightIndentation = parseMeasurement(block.dataset.rightIndentation, 'cm', {
              defaultUnit: 'pt',
            });
          } else if (style && style.p && style.p.ind && style.p.ind.r != null) {
            indentation.rightIndentation = parseMeasurement(`${style.p.ind.r}`, 'cm', {
              defaultUnit: 'pt',
            });
          } else if (listLevelDefenition && listLevelDefenition.ind?.r != null) {
            indentation.rightIndentation = parseMeasurement(
              listLevelDefenition.ind.r.toString(),
              'cm',
              {
                defaultUnit: 'pt',
              },
            );
          }

          return indentation;
        }
      }
    }
    return null;
  }

  // ------------------------------------------------------
  //                  Field Elements
  // ------------------------------------------------------
  confirmDeleteCaption() {
    return (
      this.isEditorRunning &&
      this.editionManager?.handleRemoveSelection({ confirmDeleteCaption: true })
    );
  }

  getAvailableCaptions() {
    if (this.isEditorRunning && this.DataManager?.captions) {
      return this.DataManager.captions.availableCaptions();
    }
  }

  getCaption(label: string) {
    if (this.isEditorRunning && this.DataManager?.captions) {
      return this.DataManager.captions.caption(label);
    }
  }

  createCaptionLabel(label: string) {
    if (this.isEditorRunning && this.DataManager?.captions) {
      return this.DataManager.captions.createCaption(label);
    }
  }

  deleteCaptionLabel(label: string) {
    if (this.isEditorRunning && this.DataManager?.captions) {
      return this.DataManager.captions.deleteCaption(label);
    }
  }

  insertCaption(options: Editor.Edition.CaptionCommandOptions) {
    return this.isEditorRunning && this.editionManager?.insertCaptionElement(options);
  }

  editCaption(options: any) {
    return this.isEditorRunning && this.editionManager?.editCaptionElement(options);
  }

  insertCrossReference(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    return this.isEditorRunning && this.editionManager?.insertCrossReferenceElement(options);
  }

  editCrossReference(options: Editor.Data.CrossReferences.PresentationTextOptionsType) {
    return this.isEditorRunning && this.editionManager?.editCrossReferenceElement(options);
  }

  updateCrossReference() {
    return this.isEditorRunning && this.editionManager?.updateCrossReferenceElement();
  }

  updateAllCrossReferences() {
    return this.isEditorRunning && this.DataManager?.crossReferences.updateAllCrossReferences();
  }

  getCrossReferencePresentationText(options: any) {
    return (
      (this.isEditorRunning &&
        this.DataManager?.crossReferences.getCrossReferencePresentationText(options)) ||
      null
    );
  }

  handleCrossReferenceSelected(target: any) {
    if (this.isEditorRunning && this.navigationManager && target) {
      const [block, child] = target.split(':');
      this.navigationManager.renderDocumentAtId(block, child, { position: 'INSIDE_END' });
    }
  }

  selectNodeContents(target: HTMLElement) {
    return this.isEditorRunning && this.selectionManager?.selectNodeContents(target);
  }

  // ------------------------------------------------------
  //                  Navigation
  // ------------------------------------------------------

  // TODO: refector this to ne selection type
  async renderDocumentAtMarker(marker: any) {
    if (this.isEditorRunning && this.navigationManager) {
      return this.navigationManager.renderDocumentAtMarker(marker);
    }
  }

  // ------------------------------------------------------
  //                  Locks
  // ------------------------------------------------------
  getApprovedNodes() {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      return this.DataManager.nodes.getApprovedNodes();
    }
    return Promise.reject();
  }

  getApprovedNodesByStyleId(styleId: string) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      return this.DataManager.nodes.getApprovedNodesByStyleId(styleId);
    }
    return Promise.reject();
  }

  // ------------------------------------------------------
  //                  Token
  // ------------------------------------------------------
  getUserToken() {
    return this.isEditorRunning && this.getUser()?.token;
  }

  // ------------------------------------------------------
  //                  Zoom
  // ------------------------------------------------------
  zoomIn() {
    ReduxInterface.zoomIn();
  }

  zoomOut() {
    ReduxInterface.zoomOut();
  }

  // ------------------------------------------------------
  //                  Proofing
  // ------------------------------------------------------

  async getCountSelectionElements() {
    if (this.isEditorRunning && this.DataManager?.proofing) {
      const docid = this.DataManager.document.getDocumentId() || '';
      let range = this.DataManager.selection?.getSelectionModel(docid)?.getLocalSelectionData();

      if (range) {
        return await this.DataManager.proofing.getCountSelectionElements(range.ranges);
      } else {
        Logger.captureException(new Error('No selection!'));
        return null;
      }
    }
  }

  // ------------------------------------------------------
  //                  TABULATIONS
  // ------------------------------------------------------
  setDefaultTabStop(value: number) {
    if (this.isEditorRunning && this.DataManager?.structure) {
      this.DataManager.structure.setDefaultTabStop(value);
      this.DataManager.history.createPatch();
    }
  }

  getDefaultTabStop() {
    if (this.isEditorRunning && this.DataManager?.structure) {
      return this.DataManager.structure.getDefaultTabStop();
    }
  }

  updateCustomTabsInNode(id: string, tabs: Editor.Data.TabStop[]) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.updateCustomTabs(id, tabs);
      this.DataManager.history.createPatch();
    }
  }

  addCustomTabToNode(id: string, tab: Editor.Data.TabStop) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.addCustomTab(id, tab);
      this.DataManager.history.createPatch();
    }
  }

  editCustomTabStopInNode(id: string, tab: Editor.Data.TabStop, value: Editor.Data.TabStop['v']) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.editCustomTabStop(id, tab, value);
      this.DataManager.history.createPatch();
    }
  }

  editCustomTabLeaderInNode(id: string, tab: Editor.Data.TabStop, value: Editor.Data.TabStop['l']) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.editCustomTabLeader(id, tab, value);
      this.DataManager.history.createPatch();
    }
  }

  editCustomTabAlignmentInNode(
    id: string,
    tab: Editor.Data.TabStop,
    value: Editor.Data.TabStop['t'],
  ) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.editCustomTabAlignment(id, tab, value);
      this.DataManager.history.createPatch();
    }
  }

  deleteCustomTabFromNode(id: string, tab: Editor.Data.TabStop) {
    if (this.isEditorRunning && this.DataManager?.nodes) {
      this.DataManager.nodes.deleteCustomTab(id, tab);
      this.DataManager.history.createPatch();
    }
  }

  // ------------------------------------------------------
  //                  WIDGETS
  // ------------------------------------------------------
  updateWidgets() {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
    }
  }

  addWidget(...args: Editor.Visualizer.AddWidgetArgs) {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.addWidget(...args);
    }
  }

  removeWidget(type: Editor.Visualizer.WidgetTypes, viewId: string) {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.removeWidget(type, viewId);
    }
  }

  removeAllWidgetsForType(type: Editor.Visualizer.WidgetTypes) {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.removeAllWidgetsForType(type);
    }
  }

  hideDynamicWidgets() {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.hideDynamicWidgets();
    }
  }

  rebuildWidgets() {
    if (this.isEditorRunning && this.visualizerManager) {
      this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
    }
  }
}

export default EditorManager;
