import { uniq } from 'lodash';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { notify } from '_common/components/ToastSystem';
import { navigateToError403, navigateToError404 } from 'router/history';
import { EditorService, InstanceService } from '_common/services';

import { resetAppState, updateData } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { getPublicGroups } from 'App/redux/publicSlice';
import { closeModal } from '_common/modals/ModalsSlice';
import { paths } from '_types/api';
import { selectRolesList } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { selectCommentsAuthors } from './CommentsSlice';
import { selectSuggestionsAuthors } from './TrackedActionsSlice';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import { loadStyles } from './StylesSlice';

const SLICE_NAME = 'EDITOR_STATUS';

/**
 * undefined = 'l' (left)
 */
type Alignment = { a: undefined | 'c' | 'r' | 'j' };

export type EditorStatusSlice = {
  readOnly: boolean;
  documentId: ObjectId;
  loading: boolean | TranslationId;
  paragraphsLoaded: boolean;
  visible: boolean;
  editable: boolean;
  loadedVersion: number | null;
  versionHistory: boolean;
  viewMode: string; //This is not being used but might be needed for the future
  selection: Editor.Styles.ContentStatus;
  selectedStyle: { id?: string; changed?: boolean; p?: Alignment };
  renamingDocument: boolean;
  usersOnline: UserId[];
  zoom: number;
  layout?: Editor.Visualizer.LayoutType;
  nodeToFocus?: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node';
    objectId: ObjectId;
    documentId: ObjectId;
  };
  initiatedWithTasks: boolean;
  undo: boolean;
  redo: boolean;
  blockWindow: {
    start: number;
    end: number;
  };
};

const initialState: EditorStatusSlice = {
  readOnly: false,
  loading: false,
  paragraphsLoaded: false,
  visible: false,
  editable: false,
  versionHistory: false,
  renamingDocument: false,
  documentId: '',
  loadedVersion: null,
  viewMode: 'CANVAS',
  selection: {
    TEXT: true,
    IMAGE: null,
    TABLE: null,
    EQUATION: null,
    EDITABLE: true,
    COMMENT: [],
    TRACKED: [],
    TASK: false,
    FIELD: null,
    BLOCK: null,
    LINK: false,
    REFSECTION: false,
    BLOCK_IDS: [],
    LIST: false,
    NOTE: false,
    CITATION: false,
    TABULATIONS: [],
    COLLAPSED: true,
    SECTION: null,
    TOC: null,
    TOL: null,
  },
  selectedStyle: {},
  usersOnline: [],
  zoom: 1,
  initiatedWithTasks: false,
  undo: false,
  redo: false,
  blockWindow: {
    start: 0,
    end: 0,
  },
};

//#region
export const getDocumentInfo = createAsyncThunk(
  `${SLICE_NAME}/getDocumentInfo`,
  async ({ objectId, open }: { objectId: ObjectId; open?: boolean }, { dispatch, signal }) => {
    try {
      const { data } = await new InstanceService({
        errorsExpected: [400, 403, 404],
      }).getObjectData({ objectId, objectType: 'document', open }, { signal });

      // @ts-expect-error InstanceService getObjectData not typed
      dispatch(setDocumentId(data.id || data._id));
      // @ts-expect-error InstanceService getObjectData not typed
      dispatch(updateData({ objectId: data.id, data }));
      //dispatch(listCitations(id));
      const groups = [
        // @ts-expect-error InstanceService getObjectData not typed
        ...Object.keys(data.permissions.groups),
        // @ts-expect-error InstanceService getObjectData not typed
        ...Object.keys(data.permissions.roles.groups),
      ];
      dispatch(getPublicGroups(groups));
      dispatch(closeModal('EditorErrorModal'));
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          navigateToError404();
        } else if (error?.response?.status === 403) {
          navigateToError403();
        } else if (error?.response?.status === 404) {
          navigateToError404();
        }
      }
    }
  },
);

export const documentAddKeyword = createAsyncThunk(
  `${SLICE_NAME}/documentAddKeyword`,
  async (
    { documentId, keywordValue }: { documentId: ObjectId; keywordValue: string },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400, 403] }).addKeyword(
        documentId,
        keywordValue,
      );
      dispatch(updateData({ objectId: documentId, data: { keywords: data } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          const errorData = error.response.data;
          if (errorData.keyword && errorData.keyword[0] === 'repeated') {
            notify({
              type: 'error',
              title: 'global.error',
              message: 'ERROR.ERROR_KEYWORD_ALREADY_EXISTS',
            });
          } else {
            notify({
              type: 'error',
              title: 'global.error',
              message: 'ERROR.ERROR_ADD_KEYWORD',
            });
          }
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const documentRemoveKeyword = createAsyncThunk(
  `${SLICE_NAME}/documentRemoveKeyword`,
  async (
    { documentId, keywordIndex }: { documentId: ObjectId; keywordIndex: number },
    { dispatch },
  ) => {
    try {
      const response = await new EditorService({ errorsExpected: [400] }).removeKeyword(
        documentId,
        keywordIndex,
      );
      dispatch(updateData({ objectId: documentId, data: { keywords: [...response.data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_REMOVING_KEYWORD',
          });
        }
      }
    }
  },
);

export const addAuthor = createAsyncThunk(
  `${SLICE_NAME}/addAuthor`,
  async ({ documentId }: { documentId: ObjectId }, { dispatch }) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400, 403] }).addAuthor(
        documentId,
      );
      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));

      dispatch(
        addMetaInformation({
          documentId,
          params: { field: 'affiliation', author: data.length - 1 },
        }),
      );
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_ADD_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const removeAuthor = createAsyncThunk(
  `${SLICE_NAME}/removeAuthor`,
  async (
    { documentId, authorIndex }: { documentId: ObjectId; authorIndex: number },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400, 403] }).removeAuthor(
        documentId,
        authorIndex,
      );
      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_REMOVE_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const reorderAuthors = createAsyncThunk(
  `${SLICE_NAME}/reorderAuthors`,
  async (
    { documentId, params }: { documentId: ObjectId; params: { current: number; new: number } },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({ errorsExpected: [400, 403] }).reorderAuthors(
        documentId,
        params,
      );

      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_UPDATING_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const updateMetaInformation = createAsyncThunk(
  `${SLICE_NAME}/updateMetaInformation`,
  async (
    {
      documentId,
      params,
    }: {
      documentId: ObjectId;
      params: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data'];
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({
        errorsExpected: [400, 403],
      }).updateMetaInformation(documentId, params);

      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_UPDATING_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const addMetaInformation = createAsyncThunk(
  `${SLICE_NAME}/addMetaInformation`,
  async (
    {
      documentId,
      params,
    }: {
      documentId: ObjectId;
      params: paths['/api/object/document/{document_id}/author/information/add']['post']['requestBody']['content']['multipart/form-data'];
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({
        errorsExpected: [400, 403],
      }).addMetaInformation(documentId, params);

      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_UPDATING_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const removeMetaInformation = createAsyncThunk(
  `${SLICE_NAME}/removeMetaInformation`,
  async (
    {
      documentId,
      params,
    }: {
      documentId: ObjectId;
      params: paths['/api/object/document/{document_id}/author/information/remove']['post']['requestBody']['content']['multipart/form-data'];
    },
    { dispatch },
  ) => {
    try {
      const { data } = await new EditorService({
        errorsExpected: [400, 403],
      }).removeMetaInformation(documentId, params);

      dispatch(updateData({ objectId: documentId, data: { authors: [...data] } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_UPDATING_AUTHOR',
          });
        } else if (error?.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const updateZoomValue = createAsyncThunk('updateZoom', (value: number, { getState }) => {
  let newZoomValue = value;

  if (isNaN(newZoomValue)) {
    return 1;
  }

  if (value > 200) {
    newZoomValue = 200;
  }
  if (value < 50) {
    newZoomValue = 50;
  }

  if (newZoomValue <= 200 || newZoomValue >= 50) {
    const roundValue = Math.round(newZoomValue / 10) * 10;
    newZoomValue = roundValue;
  }

  const userId = (getState() as RootState).auth.userId;
  const documentId = (getState() as RootState).editor.status.documentId;

  window.localStorage.setItem(`${userId}-${documentId}-zoom`, `${newZoomValue}`);
  return newZoomValue / 100;
});
//#endregion

const TrackingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setReadOnlyValue: (state, action: PayloadAction<EditorStatusSlice['readOnly']>) => {
      state.readOnly = action.payload;
    },
    setEditableValue: (state, action: PayloadAction<EditorStatusSlice['editable']>) => {
      state.editable = action.payload;
    },

    setVisibleValue: (state, action: PayloadAction<EditorStatusSlice['visible']>) => {
      state.visible = action.payload;
    },
    setLoadingValue: (state, action: PayloadAction<EditorStatusSlice['loading']>) => {
      state.loading = action.payload;
    },
    setParagraphsLoadedValue: (
      state,
      action: PayloadAction<EditorStatusSlice['paragraphsLoaded']>,
    ) => {
      state.paragraphsLoaded = action.payload;
    },
    setVersionHistoryValue: (state, action: PayloadAction<EditorStatusSlice['versionHistory']>) => {
      state.versionHistory = action.payload;
    },
    setRenamingDocumentValue: (
      state,
      action: PayloadAction<EditorStatusSlice['renamingDocument']>,
    ) => {
      state.renamingDocument = action.payload;
    },
    setViewMode: (state, action: PayloadAction<EditorStatusSlice['viewMode']>) => {
      state.viewMode = action.payload;
    },
    setLoadedVersion: (state, action: PayloadAction<EditorStatusSlice['loadedVersion']>) => {
      state.loadedVersion = action.payload;
    },
    setSelectedStyle: (state, action: PayloadAction<EditorStatusSlice['selectedStyle']>) => {
      state.selectedStyle = action.payload;
    },
    setSelection: (state, action: PayloadAction<Partial<EditorStatusSlice['selection']>>) => {
      state.selection = { ...state.selection, ...action.payload };
    },
    setDocumentId: (state, action: PayloadAction<EditorStatusSlice['documentId']>) => {
      state.documentId = action.payload;
    },
    setUsersOnline: (state, action: PayloadAction<EditorStatusSlice['usersOnline']>) => {
      state.usersOnline = action.payload;
    },
    addUserOnline: (state, action: PayloadAction<EditorStatusSlice['usersOnline'][number]>) => {
      state.usersOnline.push(action.payload);
    },
    removeUserOnline: (state, action: PayloadAction<EditorStatusSlice['usersOnline'][number]>) => {
      const index = state.usersOnline.indexOf(action.payload);
      if (index > -1) {
        state.usersOnline.splice(index, 1);
      }
    },
    setLayout: (state, action: PayloadAction<EditorStatusSlice['layout']>) => {
      state.layout = action.payload;
    },
    setNodeToFocus: (state, action: PayloadAction<EditorStatusSlice['nodeToFocus']>) => {
      state.nodeToFocus = action.payload;
    },
    setInitiatedWithTasks: (
      state,
      action: PayloadAction<EditorStatusSlice['initiatedWithTasks']>,
    ) => {
      state.initiatedWithTasks = action.payload;
    },
    setCanUndo: (state, action: PayloadAction<EditorStatusSlice['undo']>) => {
      state.undo = action.payload;
    },
    setCanRedo: (state, action: PayloadAction<EditorStatusSlice['redo']>) => {
      state.redo = action.payload;
    },
    setBlockWindow: (state, action: PayloadAction<EditorStatusSlice['blockWindow']>) => {
      state.blockWindow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateZoomValue.fulfilled, (state, action) => {
        state.zoom = action.payload;
      })
      .addCase(loadStyles, (state, action) => {
        if (state.selectedStyle?.id && !action.payload[state.selectedStyle.id]) {
          state.selectedStyle = {};
        }
      })
      .addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
        return initialState;
      });
  },
});

//#region Selectors
export const getDocumentObject = createSelector(
  [(state: RootState) => state.app.data, (state: RootState) => state.editor.status.documentId],
  (data, documentId) => {
    return data[documentId];
  },
);

// TODO: Replace this with the shared_with object property
export const selectDocumentUsers = createSelector(
  [
    getDocumentObject,
    (state) => state.onboarding.active.editor,
    (state) => state.onboarding.started.editor,
  ],
  (document, onboardingIsActive, onboardingHasStarted) => {
    if (!document) {
      return [];
    }

    if (onboardingIsActive || onboardingHasStarted) {
      return [GHOST_USERS.davidBean.id];
    }

    return uniq([document.owner, ...document.shared_with]);
  },
);

export const selectDocumentPermissions = createSelector(
  [getDocumentObject, selectRolesList, (state: RootState) => state.public.groups.profiles],
  (document, { data: roles }, groups) => {
    if (!document) {
      return {};
    }
    const owners = {};
    const byUser: { [key in UserId]?: Partial<Permission.Block> } = {};
    const users: { [key in UserId]?: Partial<Permission.Block> } = {};
    // user permissions
    Object.keys(document.permissions.users).forEach((userId) => {
      if (!users[userId]) {
        users[userId] = {};
      }
      const permissions = document.permissions.users[userId];
      // @ts-expect-error Objekt isn't fully typed
      permissions.forEach((permission) => {
        // @ts-expect-error Objekt isn't fully typed
        users[userId][permission] = 1;
      });
    });
    // user roles
    Object.keys(document.permissions.roles.users).forEach((userId) => {
      const userRoles = document.permissions.roles.users[userId];
      if (!users[userId]) {
        users[userId] = {};
      }
      // @ts-expect-error Objekt isn't fully typed
      userRoles.forEach((roleId) => {
        if (roles?.dict[roleId]) {
          roles.dict[roleId].granted.forEach((permission) => {
            // @ts-expect-error Objekt isn't fully typed
            users[userId][permission] = 1;
          });
        }
      });
    });
    // group permissions
    Object.keys(document.permissions.groups).forEach((groupId) => {
      const group = groups[groupId];
      if (group) {
        const permissions = document.permissions.groups[groupId];
        // @ts-expect-error Objekt isn't fully typed
        permissions.forEach((permission) => {
          group.users.forEach((userId) => {
            if (!users[userId]) {
              users[userId] = {};
            }
            // @ts-expect-error Objekt isn't fully typed
            users[userId][permission] = 1;
          });
        });
      }
    });
    // group roles
    Object.keys(document.permissions.roles.groups).forEach((groupId) => {
      const group = groups[groupId];
      if (group) {
        const groupRoles = document.permissions.roles.groups[groupId];
        // @ts-expect-error Objekt isn't fully typed
        groupRoles.forEach((roleId) => {
          if (roles?.dict[roleId]) {
            roles.dict[roleId].granted.forEach((permission) => {
              group.users.forEach((userId) => {
                if (!users[userId]) {
                  users[userId] = {};
                }
                // @ts-expect-error Objekt isn't fully typed
                users[userId][permission] = 1;
              });
            });
          }
        });
      }
    });

    // fill owners list
    Object.keys(users).forEach((userId) => {
      if (`${userId}` === `${document.owner}`) {
        delete users[userId];
      }
    });

    return {
      byUser,
      owners,
      users,
    };
  },
);

export const selectUser = createSelector(
  [(state: RootState) => state.localStorage.accounts, (state: RootState) => state.auth.userId],
  (accounts, currentUserId) => {
    return {
      id: currentUserId,
      token: accounts[currentUserId]?.token,
    };
  },
);

export const selectReadOnlyMode = createSelector(
  [
    getDocumentObject,
    (state: RootState) => state.editor.status.readOnly,
    (state: RootState) => state.editor.status.versionHistory,
  ],
  (document, readOnly, versionHistory) => {
    if (versionHistory || readOnly || document?.statusInfo?.edit === false) {
      return true;
    }

    return false;
  },
);

export const selectIsPageLayout = createSelector(
  [(state: RootState) => state.editor.status.layout],
  (layout) => {
    return layout === 'PAGE';
  },
);

export const selectFilteredCommentsActive = createSelector(
  [(state: RootState) => state.editor.status.selection.COMMENT],
  (active) => {
    return active.map(({ ref }) => ref);
  },
);

export const selectTrackedActionsActive = createSelector(
  [(state: RootState) => state.editor.status.selection.TRACKED],
  (active) => {
    return active.map(({ ref }) => ref);
  },
);

export const selectCanApprove = createSelector(
  [
    (state: RootState) => state.editor.status.versionHistory,
    getDocumentObject,
    (state: RootState) => state.app.information.actions,
    selectReadOnlyMode,
  ],
  (versionHistory, doc, information, isReadOnlyMode) => {
    //@ts-expect-error state.app.information type is wrong
    const canApprove = information?.editor.nodes.approve;
    return (
      !versionHistory &&
      doc.status !== 'approved' &&
      //@ts-expect-error Objekt type not fully complete
      (doc.user_permissions.some((permission) =>
        ['admin', 'owner', 'approve'].includes(permission),
      ) ||
        canApprove) &&
      !isReadOnlyMode
    );
  },
);

export const selectCanChangePermission = createSelector(
  [
    (state: RootState) => state.editor.status.versionHistory,
    getDocumentObject,
    (state: RootState) => state.app.information.actions,
    selectReadOnlyMode,
  ],
  (versionHistory, doc, information, isReadOnlyMode) => {
    //@ts-expect-error state.app.information type is wrong
    const canChangePermissions = information?.editor.nodes.permissions;
    return (
      !versionHistory &&
      doc.status !== 'approved' &&
      //@ts-expect-error Objekt type not fully complete
      (doc.user_permissions.some((permission) =>
        ['admin', 'owner', 'add_permission', 'remove_permission'].includes(permission),
      ) ||
        canChangePermissions) &&
      !isReadOnlyMode
    );
  },
);

export const selectCollaborators = createSelector([getDocumentObject], (document) => {
  return [...Object.keys(document.subject_permissions), document.owner];
});

export const selectCollaboratorsAndCommentsAuthors = createSelector(
  [getDocumentObject, selectCommentsAuthors],
  (document, commentsAuthors) => {
    return [
      ...(document.shared_with as string[]).map((id) => {
        return { id, imported: false };
      }),
      { id: document.owner, imported: false },
      ...commentsAuthors,
    ].filter(
      (collaborator, index, self) => index === self.findIndex((c) => c.id === collaborator.id),
    );
  },
);

export const selectCollaboratorsAndSuggestionsAuthors = createSelector(
  [getDocumentObject, selectSuggestionsAuthors],
  (document, suggestionsAuthors) => {
    return [
      ...(document.shared_with as string[]).map((id) => {
        return { id, imported: false };
      }),
      { id: document.owner, imported: false },
      ...suggestionsAuthors,
    ].filter(
      (collaborator, index, self) => index === self.findIndex((c) => c.id === collaborator.id),
    );
  },
);

export const selectWordCountHasSelection = createSelector(
  [(state: RootState) => state.editor.status.selection],
  (selectionList) => {
    const acceptedProperties: (keyof EditorStatusSlice['selection'])[] = [
      'IMAGE',
      'TABLE',
      'EQUATION',
      'COMMENT',
      'TASK',
      'FIELD',
      'LINK',
      'REFSECTION',
      'LIST',
      'NOTE',
    ];

    return (
      !selectionList.COLLAPSED ||
      Object.entries(selectionList)
        .filter(([key]) => {
          const typedKey = key as keyof EditorStatusSlice['selection'];
          return acceptedProperties.includes(typedKey);
        })
        .some(([_, selectionValue]) =>
          Array.isArray(selectionValue) ? selectionValue.length > 0 : selectionValue,
        )
    );
  },
);

//#endregion

const persistConfig = {
  key: 'status',
  storage,
  whitelist: ['nodeToFocus'],
};

const trackingReducer = persistReducer(persistConfig, TrackingSlice.reducer);

export const {
  setReadOnlyValue,
  setEditableValue,
  setVisibleValue,
  setLoadingValue,
  setParagraphsLoadedValue,
  setViewMode,
  setLoadedVersion,
  setVersionHistoryValue,
  setRenamingDocumentValue,
  setSelectedStyle,
  setSelection,
  setDocumentId,
  setUsersOnline,
  addUserOnline,
  removeUserOnline,
  setLayout,
  setNodeToFocus,
  setInitiatedWithTasks,
  setCanUndo,
  setCanRedo,
  setBlockWindow,
} = TrackingSlice.actions;

export default trackingReducer;
